import React from 'react'
import { useParams, useHistory } from 'react-router-dom';
import Chart from '../../components/chart/chart'
import Button from '../../components/form/button'
import { StoreContext } from '../../store';

const Stock = () => {
  const { ticker } = useParams();
  const { goBack, push } = useHistory();
  const { store: { preferences: { stocks } = {} } } = React.useContext(StoreContext);
  const goNext = React.useCallback(() => {
    push(`/cabinet/stock/${[].concat(stocks, stocks)[stocks.findIndex(item => item === ticker) + 1]}`);
  }, [push, stocks, ticker]);

  return (
    <div className="bodySec">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="homeTxt">
              <Chart
                ticker={ticker}
                showPredictions
              />
            </div>
            <div className="homeGraph homeGraphNone homeGraphFlex">
              <Button onClick={() => goBack()}>
                Go back
              </Button>
              <Button onClick={() => goNext()}>
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stock;
