import React from "react";
import useApi, {CONTROLLER} from "./useApi";
import useDebounce from "./useDebounce";

const usePromo = (planId, value = "") => {
  const [promo, _setPromo] = React.useState("");
  const {loading, payload, execute} = useApi(CONTROLLER.PROMO);
  const [debouncedPromo, fixed] = useDebounce(promo, 1500);

  const setPromo = React.useCallback((value) => {
    _setPromo((value || "").toUpperCase());
  }, [])

  const isPromo = React.useMemo(
    () => !loading && fixed && (debouncedPromo || '').length > 0 && payload?.success,
    [loading, fixed, debouncedPromo, payload?.success],
  );

  const isNotPromo = React.useMemo(
    () => !loading && fixed && (debouncedPromo || '').length > 0 && !payload?.success,
    [loading, fixed, debouncedPromo, payload?.success],
  );

  React.useEffect(() => {
    setPromo(value);
  }, [setPromo, value])

  React.useEffect(() => {
    if ((debouncedPromo || '').length > 0) {
      execute({code: debouncedPromo}, {urlParams: {planId}})
    }
  }, [execute, planId, debouncedPromo]);

  return {debouncedPromo, promo, setPromo, isPromo, isNotPromo, loading, data: payload?.data || payload?.message};
}

export default usePromo;