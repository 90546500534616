import React from "react";
import { Link } from "react-router-dom";
import Story from '../../assets/images/story-img.png'

const OurStory = () => (
  <div className="bodySec storySec">
    <div className="container">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
          <div className="whatWeDoTxt">
            <h2>
              <span>Our Story</span>
            </h2>
            <h4>
              <span>High-Performance Tools for Every Kind of Investor</span>
            </h4>
            <p>
              We are a small, closely-knit team of Wall Street professionals
              who saw an opportunity. Our mission is to give every type of
              investor access to cost-effective tools and technology to take
              them into the next generation of trading.{" "}
            </p>
            <p>
              Our models use Neural Networks that make complex time-series
              predictions. This approach allows the model to pick up on
              patterns and trends that are not generally evident to investors.
              In our experience, it improves accuracy and removes a lot of the
              analytical work involved in creating projection models.
            </p>
            <p>
              Best of all, we will customize your DeepTrading iteration to
              reflect the way you operate. In other words, we’re here to help
              you advance your efforts, and we’ve got the tools to help you do
              it.
            </p>
            <Link to="/pricing-plans" className="defaultBtn">
              Activate your subscription today
            </Link>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <div className="shadowImg">
            <img src={Story} alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default OurStory;
