import React from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import { Pagination, PaginationItem } from 'reactstrap'

const PaginationTemp = ({ showPerPage, total }) => {
  const { push } = useHistory();
  const { page = '1' } = useParams();
  const counter = React.useMemo(() => parseInt(page, 10), [page]);
  const [numberOfButtons, setNumberOfButtons] = React.useState(0);

  React.useEffect(() => {
    const max = Math.ceil(total / showPerPage);
    if (max < counter) {
      push(`/cabinet/stocks/${max}`)
    }
    setNumberOfButtons(max);
  }, [total, setNumberOfButtons, showPerPage, counter, push]);

  const buttons = React.useMemo(() => new Array(numberOfButtons), [numberOfButtons]);

  if (total <= showPerPage) {
    return null
  }
  
  return (
    <Pagination listClassName="flex-wrap">
      <PaginationItem>
        <Link to={`/cabinet/stocks/${counter > 1 ? counter - 1 : 1}`}>
          <i className="fa fa-angle-left" aria-hidden="true" />
        </Link>
      </PaginationItem>
      
      {buttons.fill("").map((value, index) => (
        <PaginationItem key={index}>
          <Link
            to={`/cabinet/stocks/${index + 1}`}
            className={index + 1 === counter ? "active" : "false"}
          >
            {index + 1}
          </Link>
        </PaginationItem>
      ))}

      <PaginationItem>
        <Link to={`/cabinet/stocks/${numberOfButtons > counter ? counter + 1 : numberOfButtons}`}>
          <i className="fa fa-angle-right" aria-hidden="true" />
        </Link>
      </PaginationItem>
    </Pagination>
  );
}

export default PaginationTemp;
