import React from 'react'
import { Link } from 'react-router-dom'
import WhatWeDoImg from '../../assets/images/what-we-do-img.png'

const WhatWeDo = () => (
  <div className="bodySec whatWeDoSec">
    <div className="container">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-5 col-xl-5">
          <div className="shadowImg">
            <img src={WhatWeDoImg} alt="" />
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-7 col-xl-7">
          <div className="whatWeDoTxt">
            <h2>
              <span>What we Do</span>
            </h2>
            <h4>
              <span>Wall Street’s Best-Kept Secret</span>
            </h4>
            <p>
              DeepTrading.ai leverages advanced Artificial Intelligence (AI)
              Deep Learning algorithms to predict next-day stock prices,
              along with weekly, monthly, three-month, and yearly stock
              price predictions resulting in alpha predictions that
              support your trading activity.
            </p>
            <h3>Subscription-Based Service</h3>
            <p>
              We offer three levels of subscription for every type of
              investor. One flat fee, charged monthly or yearly, provides
              access to our proprietary deep learning models and algorithms
              for a list of 250 stocks.
            </p>
            <ul>
              <li>
                The Basic Plan gives access to daily and weekly stock price predictions.
              </li>
              <li>
                The Advanced Plan gives access to daily, weekly, monthly, three-month, and yearly
                predictions and leverages more advanced features for more sophisticated concerns.
              </li>
              <li>
                The Enterprise Plan is for enterprise users who require performance.
              </li>
            </ul>
            <p>
              In addition, our Deep Index—our next-day predicted price of the Dow
              Industrial Average Index—can be viewed on our homepage, free of charge, by anyone.
            </p>
            <p>
              We provide value to all types of investors. Whether you are a
              finance professional or just getting started, our models are
              designed to be used right out-of-the-box or can be customized
              according to your specific needs.
            </p>
            <p>
              <Link to="/contact-us">Reach out today</Link>&nbsp;
              to learn more about our plans and talk to us about your specific
              needs. We’d love to help you take your trading to the next
              level.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default WhatWeDo;
