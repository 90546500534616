import React from "react";
import { Link } from "react-router-dom";

const Footer = () => (
  <section className="footerSec">
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
            <div className="copyTxt">
              <p>© 2021 DeepTrading.ai, All Rights Reserved</p>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div className="termLinks">
              <Link to="/policy">Privacy Policy</Link>
              &nbsp;|&nbsp;
              <Link to="/terms">Terms of Use</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </section>
);

export default Footer;
