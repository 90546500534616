import React from 'react'
import { useHistory } from 'react-router-dom'
import useAOS from '../../hooks/useAOS'
import Popup from '../../components/popup/popup'
import Field from '../../components/form/field'
import { Form } from '../../components/form/form'
import useApi, { CONTROLLER } from '../../hooks/useApi'
import Button from '../../components/form/button'

const Contact = () => {
  useAOS();
  const { push } = useHistory();
  const { loading, payload, execute } = useApi(CONTROLLER.CONTACT);
  const upShow = React.useRef();
  const messagesEndRef = React.useRef();
  
  const [state, setState] = React.useState({
    show: false,
    message: "",
    title: "",
  });

  const submitForm = async (values) => {
    const { message } = await execute(values);

    setState((state) => ({
      ...state,
      show: true,
      title: "Contact Us Confirmation",
      message,
    }));
  };

  const handleClose = () => {
    setState((state) => ({
      ...state,
      show: false,
      title: "",
      message: "",
    }));

    if (payload?.success) {
      push('/');
    }
  };

  return (
    <>
      <div ref={upShow} className="bodySec contactSec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
              <div className="whatWeDoTxt">
                <h2>
                  <span>Contact Us</span>
                </h2>
                <div className="contactForm">
                  <Form
                    onSubmit={submitForm}
                    className="AnimationForm"
                    disabled={loading}
                  >
                    <Field
                      type="text"
                      name="firstname"
                      label="First Name"
                      required
                      disabled={loading}
                    />
                    <Field
                      type="text"
                      name="lastname"
                      label="Last Name"
                      required
                      disabled={loading}
                    />
                    <Field
                      type="email"
                      name="email"
                      label="Email Address"
                      required
                      disabled={loading}
                    />
                    <Field
                      type="text"
                      name="number"
                      label="Phone Number"
                      required
                      disabled={loading}
                    />
                    <Field
                      type="textarea"
                      required
                      name="message"
                      label="Your Message"
                      disabled={loading}
                    />
                    <div className="field">
                      <Button loading={loading} right>
                        Send Message
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="contactInfo">
                <h6>DeepTrading.ai</h6>
                <ul>
                  {/* <li>
                    <span>Call Us:</span>
                    <a href="tel:+7324230343">732-423-0343</a>
                  </li> */}
                  <li>
                    <span>Email:</span>
                    <a href="mailto://info@deeptrading.ai">
                      info@deeptrading.ai
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Popup
          show={state.show}
          title={state.title}
          message={state.message}
          handleClose={handleClose}
        />
      </div>
      <div ref={messagesEndRef} />
    </>
  );
}

export default Contact;
