import React from 'react';
import moment from 'moment';
import Grid from "../grid/grid";
import {dateToString} from "../../utils/date";
import {prettyAmount} from "../../utils/amount";
import './reports.css'
import {capitalizeAllWords} from "../../utils/text";
import Menu from "./menu";

const columns = [
  'Ticker',
  {name: 'Close', render: ({value}) => prettyAmount(value, 2)},
  {name: 'Prediction', render: ({value}) => prettyAmount(value, 2)},
  {name: 'PercentDelta', render: ({value}) => prettyAmount(value, 5)},
];

const allowed = ['daily', 'two_day', 'weekly', 'two_week', 'monthly', 'two_month', 'three_month', 'six_month', 'yearly'];

const TopPredictions = () => {
  const [date, setDate] = React.useState();
  const [value, setValue] = React.useState('daily');
  const [values, setValues] = React.useState([]);
  const afterLoad = React.useCallback((data) => {
    setDate(data?.meta?.date);
    setValues(Object
      .keys(data?.data || {})
      .map(item => item.replace('_prediction', ''))
      .filter(item => allowed.includes(item))
    );
  }, []);
  const prepareData = React.useCallback((data) => (data || {})[`${value}_prediction`], [value]);

  return (
    <>
      <Menu
        value={value}
        onChange={setValue}
        items={values}
      />
      <div className="report-main">
        <h6 className="report-name">
          Top 50 {capitalizeAllWords(value)} Predictions
          {date && (
            <>
              &nbsp;for {dateToString(moment.utc(date), 'MMMM Do, YYYY')}
            </>
          )}
        </h6>
        <Grid
          idProperty="Ticker"
          columns={columns}
          report="topPredictions"
          reportParams
          className="singleStockChartTable report-size"
          autoHeight
          sortable
          afterLoad={afterLoad}
          prepareData={prepareData}
        />
      </div>
    </>
  )
}

export default React.memo(TopPredictions);