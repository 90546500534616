import React from 'react'
import Grid from '../../../components/grid/grid'
import { prettyAmount } from '../../../utils/amount'

const columns = [
  {
    name: 'Ticker',
    header: 'Ticker',
    minWidth: 60,
    headerAlign: 'center',
    textAlign: 'center',
    defaultLocked: true,
    defaultFlex: 1,
  },
  {
    name: 'Name',
    header: 'Name',
    minWidth: 200,
    defaultFlex: 1,
    textAlign: 'start',
    headerAlign: 'start',
  },
  {
    name: 'DailyAccuracy',
    header: 'Daily Accuracy',
    minWidth: 110,
    headerAlign: 'center',
    defaultFlex: 1,
  },
  {
    name: 'WeeklyAccuracy',
    header: 'Weekly Accuracy',
    minWidth: 120,
    headerAlign: 'center',
    textAlign: 'center',
    defaultFlex: 1,
  },
  {
    name: 'MonthlyAccuracy',
    header: 'Monthly Accuracy',
    minWidth: 125,
    headerAlign: 'center',
    textAlign: 'center',
    defaultFlex: 1,
  },
  {
    name: 'ThreeMonthAccuracy',
    header: 'Three Month Accuracy',
    minWidth: 180,
    headerAlign: 'center',
    textAlign: 'center',
    defaultFlex: 1,
  }
];

const List = ({ list, disabled = false, selected: originSelected = [], handleSelect }) => {

  const onSelectionChange = ({ selected, data }) => {
    const keys = Object.keys(selected);
    if (!Array.isArray(data)) {
      if (keys.includes(data.Ticker)) {
        // add
        handleSelect([...originSelected, data.Ticker]);
      } else {
        // remove
        handleSelect(originSelected.filter(item => item !== data.Ticker));
      }
    } else {
      const temp = list.map(item => item.Ticker);
      if (data.length > 0) {
        // add all
        handleSelect([...originSelected, ...temp]);
      } else {
        // remove all
        handleSelect(originSelected.filter(item => !temp.includes(item)));
      }
    }
  }
  
  const dataSource = React.useMemo(() => (list || []).map((item) => ({
    ...item,
    DailyAccuracy: prettyAmount(item.DailyAccuracy),
    WeeklyAccuracy: prettyAmount(item.WeeklyAccuracy),
    MonthlyAccuracy: prettyAmount(item.MonthlyAccuracy),
    ThreeMonthAccuracy: prettyAmount(item.ThreeMonthAccuracy),
  })), [list]);
  
  const selectedList = React.useMemo(
    () => {
      const tickerList = (list || []).map(item => item.Ticker);
      return originSelected
        .filter(item => tickerList.includes(item))
        .reduce((result, item) => ({ ...result, [item]: true }), {})
    },
    [list, originSelected],
  );

  return (
    <Grid
      idProperty="Ticker"
      columns={columns}
      selected={selectedList}
      dataSource={dataSource}
      onSelectionChange={!disabled && onSelectionChange}
      className="selectStockModal"
      checkboxColumn
      checkboxOnlyRowSelect
      rowHeight={35}
      autoHeight
      sortable
    />
  )
}

export default List;
