import React from 'react'
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'
import Routes from './routes'
import { Store } from './store'
import * as serviceWorker from './serviceWorker'
import './assets/css/style.css'
import './index.css'

const root = createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Store>
      <Routes />
    </Store>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
