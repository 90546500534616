import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Order from '../pages/order/order'
import Stock from '../pages/stock/stock'
import Orders from '../pages/orders/orders'
import Search from "../pages/search/search";
import Stocks from '../pages/stocks/stocks'
import Logout from '../pages/logout/logout'
import Profile from '../pages/profile/profile'
import Checkout from '../pages/checkout/checkout'
import NotFound from '../pages/notFound/notFound'
import { Stocks as StocksContext } from '../stocks'

const Private = ({ match: { path }}) => {
  return (
    <StocksContext>
      <Switch>
        <Route
          path={`${path}/profile`}
          component={Profile}
        />
        <Route
          path={`${path}/search`}
          component={Search}
        />
        <Route
          path={`${path}/orders`}
          component={Orders}
        />
        <Route
          path={`${path}/order/:planId`}
          component={Order}
        />
        <Route
          path={`${path}/checkout/:planId`}
          component={Checkout}
        />
        <Route
          path={`${path}/logout`}
          component={Logout}
        />
        <Route
          path={`${path}/stocks/:page`}
          component={Stocks}
        />
        <Route
          path={`${path}/stocks`}
          component={Stocks}
        />
        <Route
          path={`${path}/stock/:ticker`}
          component={Stock}
        />
        <Route component={NotFound} />
      </Switch>
    </StocksContext>
  );
}

export default Private;
