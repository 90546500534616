import React from 'react'

const useWidth = () => {
  const ref = React.useRef();
  const [width, setBbox] = React.useState(0);

  const set = React.useCallback(
    () => setBbox(Math.round(ref.current?.getBoundingClientRect().width || 0)),
    []
  );

  React.useEffect(() => {
    set();
    window.addEventListener('resize', set);
    return () => window.removeEventListener('resize', set);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [width, (value) => {ref.current = value; set()}];
};

export default useWidth;
