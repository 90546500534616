import React from 'react'
import './policy.css'

const Policy = () => (
  <div className="bodySec aboutSec">
    <div className="container">
      <h2 className="text-center">
        <span>PRIVACY POLICY</span>
      </h2>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-bold text-italic">
          Last Update: November 10, 2020
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 privacyandPolicy">
          {/* Section Starts here */}
          <div className="text-bold">
            <span>1.</span>
            <span className="lineHeading">NEUTRAL VENUE</span>
          </div>
          <p className="text-justify marginParagraph">
            We care about your online privacy. This Privacy Policy (the
            “Policy”) describes our practices with respect to collection,
            use, disclosure and protection of your information when you
            use deeptrading.ai (the “Platform”). Note that this Policy is
            only valid on our Platform, not any third party networks, even
            if they are referenced on our Platform. It is your
            responsibility to familiarize yourself and comply with any
            relevant third party networks.<br/> If you visit our Platform, that
            signifies your legal acceptance of the Policy. You must exit
            the Platform if you do not agree with any provision(s) of this
            Privacy Policy. We reserve the right to change this Policy at
            any time at our sole discretion. The effective date of the
            last update is at the top of this page, so visit it
            occasionally to see if there are any changes.
          </p>
          {/* Section Ends here */}
          {/* Section Starts here */}
          <div className="text-bold">
            <span>2.</span>
            <span className="lineHeading">Collection of User Data </span>
          </div>
          <p className="text-justify">Here are the types of information regarding the Platform users we may collect:</p>
          <p className="text-justify marginParagraph">
            a) <span className="text-italic"> Personal Info: </span> name, email address, postal address, phone number, company name, geolocation.
          </p>
          <p className="text-justify marginParagraph">
            b) <span className="text-italic">Payment Info: </span> we do not receive your credit or debit card information. Third party payment processors like Authorize.net and PayPal handle payments, not us.
          </p>
          <p className="text-justify marginParagraph">
            c) <span className="text-italic">Communications: </span> if you contact us for any reason, we will receive whatever information you voluntarily provide (e.g., your feedback, ratings and reviews).
          </p>
          <p className="text-justify marginParagraph">
            d) <span className="text-italic">Your Devices: </span> device identifiers, phone manufacturer and carrier, browser, IP address, operating system version, mobile advertising identifiers, application installations.
          </p>
          <p className="text-justify marginParagraph">
            e) <span className="text-italic">Platform Interaction: </span> we see what content our Platform users access, when and how they interact with the Platform content/pages.
          </p>
          {/* Section Ends here */}
          {/* Section Starts here */}
          <div className="text-bold">
            <span>3.</span>
            <span className="lineHeading">Use of Data</span>
          </div>
          <p>We use the collected data for the following purposes:</p>
          <p className="text-justify marginParagraph">
            <span> a) </span> To provide the services and/or information that you have asked for.
          </p>
          <p className="text-justify marginParagraph">
            <span> b) </span> To communicate with you regarding changes to your account or our Platform.
          </p>
          <p className="text-justify marginParagraph">
            <span> c) </span> To send our newsletter but you can unsubscribe.
          </p>
          <p className="text-justify marginParagraph">
            <span> d) </span> To enforce our rights, carry out our obligations, prevent fraud, respond to government requests, facilitate disputes between users.
          </p>
          <p className="text-justify marginParagraph">
            <span> e) </span> To accomplish any other purpose for which the information was provided.
          </p>
          {/* Section Ends here */}
          {/* Section Starts here */}
          <div className="text-bold">
            <span>4.</span>
            <span className="lineHeading">Disclosure of Data</span>
          </div>
          <p>In addition to sharing your data as described above, we may disclose the collected personal information as follows:</p>
          <p className="text-justify marginParagraph">
            a) In case there is a sale, merger or other transfer in the ownership of our Platform, the successor will receive personal information about our Platform users along with the assets transferred.
          </p>
          <p className="text-justify marginParagraph">
            b) To our contractors, business partners, third party service providers and other entities or individuals who provide support for our Platform. For example, integration and API partners.
          </p>
          <p className="text-justify marginParagraph">
            c) Aggregated, anonymized information that does not identify any particular user can be disclosed without restriction.
          </p>
          <p className="text-justify marginParagraph">
            d) To respond to a court order, regulatory request or legal process.
          </p>
          <p className="text-justify marginParagraph">
            e) If we decide that disclosure is appropriate to protect the property, safety, rights of the Platform, its users or the public.
          </p>
          {/* Section Ends here */}
          {/* Section Starts here */}
          <div className="text-bold">
            <span>5.</span>
            <span className="lineHeading">Cookie Policy</span>
          </div>
          <p className="text-justify marginParagraph">
          Cookies are small bits of text data placed on your device when you visit sites. Cookies record data about your activity, such as which pages you view and what you click on. Cookies assist our Platform to recognize your device when you return. For example, cookies can help us to remember your preferences, username, analyze the performance of our Platform and recommend content that may be most relevant to your interests.
          <p>Here are the reasons we may use cookies:</p>
          </p>
          <p className="text-justify marginParagraph">
            a) <span className="text-italic"> Analytics.  </span> This type of cookies shows us which pages users view, which links are popular, etc. These cookies only provide anonymized information that does not identify anybody personally. This information is then bundled with the similar information from the other users, so that we can analyze the general usage patterns.
          </p>
          <p className="text-justify marginParagraph">
            b) <span className="text-italic">Essential cookies. </span>These are necessary to provide the services that you have asked for. Without these essential cookies, our Platform would not be able to operate. They are necessary to enable users to navigate through the Platform and use its main features. E.g., essential cookies identify registered users so that they can access member-only areas of the site. Essential cookies keep users logged in. If a subscriber disables essential cookies, that subscriber won’t be able to get to all of the content that a subscription entitles them to. These cookies don't collect information that could be utilized for the purposes of marketing or figuring out what places on the internet you have visited.
          </p>
          <p className="text-justify marginParagraph">
            c) <span className="text-italic">To show relevant advertising. </span> If we allow ads on our Platform, they can contain cookies in order to provide ads most relevant to your interests. They can also help limit the number of times you see a particular ad and to assess the effectiveness of the advertising campaign.
          </p>
          <p className="text-justify marginParagraph">
            d) <span className="text-italic">To improve your browsing experience. </span> This type of cookies enables the site to remember users’ preferences and settings, such as geographic region or language. They can also be used to restrict the number of times an ad is shown, to remember which forms you have already filled in, so that you don’t have to do it again.
          </p>
          <p className="text-justify marginParagraph">
            e) <span className="text-italic">To implement tracking technology on our Platform. </span> This tracking does not use your personal information; it uses deidentified data (i.e., data that cannot be tied specifically to you). We will not combine this data with your other personal information without your prior express permission.
          </p>
          <p className="text-justify marginParagraph">
          There is a way to turn off cookies by going to your browser’s Help or Settings menu. However, keep in mind that disabling cookies may limit your use of the Platform and/or delay or affect the way in which it operates.
          </p>
         
          {/* Section Ends here */}
          {/* Section Starts here */}
          <div className="text-bold">
            <span>6.</span>
            <span className="lineHeading">Data Security</span>
          </div>
          <p className="text-justify marginParagraph">
          Only our administrators are allowed to access our Platform’s password-protected server where your personal information is stored. We utilize SSL. However, any transmission of information over the Internet has its inherent risks, so we cannot guarantee the absolute security of your personal information. Transmit personal information over the Internet at your own risk. We shall not be liable for circumvention of security measures or privacy settings on the Platform. It is your responsibility to keep your login credentials, if any, confidential.
          </p>
          {/* Section Ends here */}
                 {/* Section Starts here */}
                 <div className="text-bold">
            <span>7.</span>
            <span className="lineHeading">Children’s Privacy</span>
          </div>
          <p className="text-justify marginParagraph">
          We do not knowingly collect any personal information about children under the age of 16. Our Platform is not directed to children under the age of 16. If we become aware that a child under 16 has provided any personal info, it will be erased from our database as soon as reasonably possible, except when we need to keep that information for legal purposes or to notify a parent or guardian. However, portions of this data may remain in back-up archives or web logs even after we erase it from our databases. If a parent or guardian believes that a child has sent us personal information, send us an e-mail.
          </p>
          {/* Section Ends here */}
         {/* Section Starts here */}
         <div className="text-bold">
            <span>8.</span>
            <span className="lineHeading">Users’ Rights, CCPA</span>
          </div>
          <p className="text-justify marginParagraph">
            a) We will not share your personal information with third parties for their direct marketing purposes to the extent it is forbidden by law. If our practices change, we will do so in accordance with applicable laws and will notify you in advance. California law requires that operators of online services disclose how they respond to a Do Not Track signal. Some browsers have incorporated “Do Not Track” features. Most of these features, when turned on, send a signal or preference to the online service that a user visits, indicating that the user does not wish to be tracked. At this time we do not respond to Do Not Track signal.
          </p>
          <p className="text-justify marginParagraph">
            b) You can request disclosure of your information collected by us by writing to the email at the end of this Policy. We will then provide the requested information, its sources and purposes of use, in a portable and easily accessible format within 45 days of the request.
          </p>
          <p className="text-justify marginParagraph">
            c) You have the right to request deletion of your personal information from our systems by submitting a request to the email at the end of this Policy.
          </p>
          <p className="text-justify marginParagraph">
            d) You have the right to nondiscrimination for exercising your rights. That means you cannot be denied goods or services, charged different prices, or provided different quality of goods/services for asserting your legal rights.
          </p>
          {/* Section Ends here */}
          {/* Section Starts here */}
          <div className="text-bold">
            <span>9.</span>
            <span className="lineHeading">
              International Transfer
            </span>
          </div>
          <p className="text-justify marginParagraph">
          We process your personal information in the United States. This is where it will be transferred to in case you are located somewhere else. By submitting any personal information to us, you agree to its transfer to and processing in the United States.
          </p>
          {/* Section Ends here */}
          {/* Section Starts here */}
          <div className="text-bold">
            <span>10.</span>
            <span className="lineHeading">EU Users’ Rights</span>
          </div>
         <p>
         This section of our Privacy Policy applies to the users of our Platform in the EU. We would like to inform you about your GDPR rights and how we safeguard them.
         </p>
         <p className="text-justify marginParagraph">
            a) <span className="text-italic">Your GDPR rights to be informed, to access, rectify, erase or restrict the processing of your personal information. </span>
            You have the right to obtain free information about what personal data we have obtained about you, where it is stored, for how long, for what purposes it is used, to whom it was disclosed. You have the right to have us, without undue delay, rectify of inaccurate personal data concerning you. That means you can request we change your personal data in our records, or have your incomplete personal data completed. You have the “right to be forgotten,” i.e. to have us delete your personal information, without undue delay, if the data is no longer necessary in relation to the purposes for which it was collected. However, GDPR gives us the right to refuse erasure if we can demonstrate compelling legitimate grounds for keeping your information.
          </p>
          <p className="text-justify marginParagraph">
          b) <span className="text-italic"> GDPR gives you the right to restrict processing if any of the following applies: </span>
          <div className="ml-3">
              <ol className="romanLowerStyle">
                <li>
                If you contest the accuracy of your personal data, we will restrict processing it for a period enabling us to verify its accuracy.
                </li>
                <li>
                The processing is unlawful and you oppose its erasure and request instead the restriction of its use.
                </li>
                <li>
                We no longer need your personal data for the purposes of the processing, but you require us to restrict processing for the establishment, exercise or defense of legal claims.
                </li>
                <li>
                You have objected to processing pursuant to Article 21(1) of the GDPR pending the verification whether our legitimate grounds override yours.
                </li>
              </ol>
            </div>
          </p>
          <p className="text-justify marginParagraph">
          c) <span className="text-italic"> Right to data portability.  </span>
          Upon request, we will provide you your personal data in our possession, in a structured, commonly used and machine-readable format. You have the right to transmit that data to another controller if doing so does not adversely affect the rights and freedoms of others.
          </p>
          <p className="text-justify marginParagraph">
          d) <span className="text-italic"> Right to object. </span>
          You can object, on grounds relating your particular situation, at any time, to processing of your personal information, if based on point (e) or (f) of Article 6(1) of the GDPR.  We will then have to stop processing, unless we can demonstrate compelling legitimate grounds for the processing. If you object to the processing for direct marketing purposes, we will have to stop processing for these purposes.
          </p>
          <p className="text-justify marginParagraph">
          e) <span className="text-italic"> Right to withdraw consent.</span>
          GDPR grants you the right to withdraw your earlier given consent, if any, to processing of your personal data at any time.
          </p>
          <p className="text-justify marginParagraph">
          f) <span className="text-italic"> Rights related to automated decision making.  </span>
          As a responsible business, we do not rely on any automated decision making, such as profiling.
          </p>
          
          {/* Section Ends here */}

              {/* Section Starts here */}
              <div className="text-bold">
            <span>11.</span>
            <span className="lineHeading">Data Security</span>
          </div>
          <p className="text-justify marginParagraph">
          You can view and edit some of your account information yourself after logging in. If you terminate account, we may retain some information for as long as necessary to evaluate Platform usage, troubleshoot issues, resolve disputes and collect any fees owed. If you have any questions or wish to ask to access, modify or delete any of your personal data on our Platform, please contact us at info@deeptrading.ai or

            <br/><br/>
            Nektron, Inc.<br/>
            99 Prospect Ave.<br/>
            Bayonne, New Jersey 07002<br/>
            Note that we can deny your request if we think it would violate any law or cause the information to be incorrect.
          </p>
          {/* Section Ends here */}
        </div>
      </div>
    </div>
  </div>
);

export default Policy;
