import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { StoreContext } from '../../../store'
import LogoImg from '../../../assets/images/logo.png'

const MenuItem = ({ link, children, ...props }) => {
  const active = useRouteMatch({ path: link, exact: true });

  return (
    <li className={active ? 'active' : undefined}>
      <Link to={link} {...props}>
        {children}
      </Link>
    </li>
  );
}

const Header = () => {
  const refMenu = React.useRef();
  const refButton = React.useRef();
  const [open, setOpen] = React.useState(false);
  const { store: { isAuth } } = React.useContext(StoreContext);

  const handleClick = () => {
    setOpen(true);
    window.$(refButton.current).toggleClass('open');
    window.$(refMenu.current).slideToggle();
  }
  
  const handleClose = () => {
    if (open) {
      handleClick();
    }
  }

  return (
    <section className="headSec">
      <header className="header fixed">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2">
              <div className="logo">
                <Link to="/">
                  <img alt="logo" src={LogoImg} />
                </Link>
              </div>
              <div id="nav-icon4" onClick={handleClick} ref={refButton}>
                <span />
                <span />
                <span />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10">
              <div className="mainMenu" ref={refMenu}>
                <ul id="menu">
                  <MenuItem
                    link="/"
                    onClick={handleClose}
                  >
                    Home
                  </MenuItem>
                  <MenuItem
                    link="/what-we-do"
                    onClick={handleClose}
                  >
                    What We Do
                  </MenuItem>
                  <MenuItem
                    link="/faq"
                    onClick={handleClose}
                  >
                    Faq
                  </MenuItem>
                  <MenuItem
                    link="/pricing-plans"
                    onClick={handleClose}
                  >
                    Pricing Plans
                  </MenuItem>
                  <MenuItem
                    link="/our-story"
                    onClick={handleClose}
                  >
                    Our Story
                  </MenuItem>
                  <MenuItem
                    link="/about-us"
                    onClick={handleClose}
                  >
                    About Us
                  </MenuItem>
                  <MenuItem
                    link="/contact-us"
                    onClick={handleClose}
                  >
                    Contact Us
                  </MenuItem>

                  {isAuth !== true && (
                    <>
                      <MenuItem
                        link="/auth/login"
                        onClick={handleClose}
                      >
                        Log In
                      </MenuItem>
                      <MenuItem
                        link="/auth/signup"
                        onClick={handleClose}
                      >
                        Sign Up
                      </MenuItem>
                    </>
                  )}
                  {isAuth === true && (
                    <>
                      <MenuItem
                        link="/cabinet/stocks"
                        onClick={handleClose}
                      >
                        Dashboard
                      </MenuItem>
                      <MenuItem
                        link="/cabinet/logout"
                        onClick={handleClose}
                      >
                        Logout
                      </MenuItem>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </section>
  );
}

export default Header;
