import React from 'react';

const useDebounce = (value, delay) => {
  const [fixed, setFixed] = React.useState(true);
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(
    () => {
      setFixed(false);
      const handler = window.setTimeout(() => {
        setDebouncedValue(value);
        setFixed(true);
      }, delay);

      return () => {
        window.clearTimeout(handler);
      };
    },
    [delay, value]
  );

  return [debouncedValue, fixed];
}

export default useDebounce;