import React from 'react';
import useApi, {CONTROLLER} from "../../hooks/useApi";
import {StoreContext} from "../../store";

const onSortInfo = (sortInfo, event, name) => {
  if (Array.isArray(sortInfo)) {
    const item = sortInfo.find(info => info.name === name);
    let curr = (item || {}).dir || 0;

    let dir = 0;
    if (curr === 1) {
      dir = -1;
    }
    if (curr === -1) {
      dir = 0;
    }
    if (curr === 0) {
      dir = 1;
    }

    if (event.shiftKey) {
      if (dir === 0) {
        return sortInfo.filter(info => info.name !== name);
      }
      if (item) {
        return sortInfo.map(info => info.name === name ? ({name, dir}) : info);
      }
      return [...sortInfo, {name, dir}];
    } else {
      if (dir === 0) {
        return [];
      }
      return [{name, dir}];
    }
  }
};

const useStorage = (ref, name, props) => {
  const {loading, execute} = useApi(CONTROLLER.PREF_SAVE, {urlParams: {name}});

  const {store: {preferences}} = React.useContext(StoreContext);
  const pref = React.useMemo(() => (preferences || {})[name] || {}, [preferences, name]);

  const [columns, setColumns] = React.useState();
  const [sortInfo, setSortInfo] = React.useState();
  const [columnOrder, setColumnOrder] = React.useState();
  const [filterValue, setFilterValue] = React.useState();

  React.useEffect(() => {
    setColumns(pref?.columns);
    setSortInfo(pref?.sortInfo || props.defaultSortInfo);
    setColumnOrder(pref?.columnOrder);
    setFilterValue(pref?.filterValue);
  }, [pref]);

  const _columns = React.useMemo(
    () => (props.columns || []).map(item => ({
      ...item,
      ...(columns?.find(column => column.name === item.name) || {}),
      headerDOMProps: {
        onClick: (event, props) => setSortInfo((sortInfo) => onSortInfo(sortInfo, event, props.name)),
        ...item.headerDOMProps,
      },
    })),
    [props.columns, columns],
  );

  const onColumnVisibleChange = React.useCallback(({column, visible}) => setColumns(
    (columns) => [
      ...(columns || [])?.filter((item) => item.name !== column.name),
      {...(columns || [])?.find((item) => item.name === column.name), name: column.name, visible},
    ]
  ), []);

  const onColumnResize = React.useCallback(({column, flex, width}) => setColumns(
    (columns) => [
      ...(columns || [])?.filter((item) => item.name !== column.name),
      {...(columns || [])?.find((item) => item.name === column.name), name: column.name, flex, width},
    ]
  ), []);

  React.useImperativeHandle(
    ref,
    () => ({
      save() {
        execute({
          columns: columns?.map(column =>
            ['name', 'visible', 'width', 'flex'].reduce(
              (previousValue, currentValue) => ({...previousValue, [currentValue]: column[currentValue]}),
              {},
            )
          ),
          sortInfo,
          columnOrder,
          filterValue,
        });
      },
      reset() {
        execute({});
      },
    }),
  );

  return {
    loading,
    sortInfo, onSortInfoChange: setSortInfo,
    columnOrder, onColumnOrderChange: setColumnOrder,
    columns: _columns,
    onColumnResize,
    onColumnVisibleChange,
    filterValue, onFilterValueChange: setFilterValue,
  };
};

export default useStorage;