import React from "react";
import "./terms.css";

const Terms = () => (
  <div className="bodySec aboutSec">
    <div className="container">
      <h2 className="text-center">
        <span>Terms of Use</span>
      </h2>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-bold text-italic">
          Last Update: November 12, 2020
        </div>
      </div>
      <div className="row">
        
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 termsofuseclass">
          <div className="text-bold">
            <span>1.</span>
            <span className="lineHeading">ACCEPTANCE</span>
          </div>
          <p className="text-justify">
            These Terms of Use (the “Terms”) govern your use of
            deeptrading.ai (the “Platform”). These Terms have a force of a
            legally binding agreement, even if you are simply browsing
            without intention to contact us or register an account. The
            term "us," "we" or "our" refers to the owners and operators of
            this Platform. The term "you" refers to the viewer of the
            Platform. Privacy Policy [insert link] is another important
            document that you should familiarize yourself with because it
            describes our practices with respect to your personal
            information. You cannot visit the Platform if you do not agree
            to these Terms or the Privacy Policy. Sometimes we modify
            these Terms. We don’t notify users about every change to the
            Terms but you can see the date of the last update at the top
            of this page. If you still wish to visit the Platform after
            said date, that constitutes your agreement to the updates.
          </p>
          <p className="text-justify text-bold">
            IMPORTANT: review the “ARBITRATION” section below. Your use of
            the Platform constitutes your agreement to arbitrate disputes
            instead of having them resolved by a court. You hereby waive
            any right to a jury trial you may have had. You also waive
            your right to participate as a plaintiff or class member in
            any purported class action or representative proceeding
            against us.
          </p>
          {/* Section Starts here */}
          <div className="text-bold">
            <span>2.</span>
            <span className="lineHeading">NEUTRAL VENUE</span>
          </div>
          <p className="text-justify marginParagraph">
            a){" "}
            <span className="text-italic">
                No Financial or Legal Advice.
              </span>
            Our stock price predictions are not 100% accurate. Price
            predictions and all other Platform content is for educational
            and informational purposes only. It is provided on an “as is”
            basis, without warranties of any kind. You should not rely on
            Platform content in making any investment. You should always
            check with your financial, investment, legal, tax and/or other
            professional advisors to determine the suitability of any
            investment. We are not financial advisors, accountants or
            attorneys. Nothing on this Platform constitutes legal,
            accounting, investing or other professional advice. We do not
            guarantee that you will make any money using our Platform.
            Nothing on this Platform is a promise or guarantee of
            earnings. WE SHALL NOT BE HELD LIABLE FOR USERS’ TRADES,
            DATA, TRANSACTIONS, INTERACTIONS AND INFORMATION. We are not
            liable for the consequences of you disclosing your personal
            information to others.
          </p>
          <p className="text-justify marginParagraph">
            b) <span className="text-italic">DRIVING.</span> DO NOT USE
            THE PLATFORM IN ANY MANNER THAT DISTRACTS YOU FROM DRIVING OR
            IS ILLEGAL (E.G., IN JURISDICTIONS THAT DO NOT ALLOW THE USE
            OF MOBILE DEVICES WHILE DRIVING). WE SHALL NOT BE LIABLE FOR
            YOUR COMPLIANCE WITH TRAFFIC LAWS.
          </p>
          <p className="text-justify marginParagraph">
            c) <span className="text-italic">Third Parties.</span> The
            Platform may contain references to third party websites and
            rely on third party services for support. We shall not be
            liable for any third party venues and cannot guarantee their
            performance. We do not monitor all content submitted to the
            Platform. We shall not be liable for user submissions or any
            third party content on the Platform.
          </p>
          {/* Section Ends here */}
          {/* Section Starts here */}
          <div className="text-bold">
            <span>3.</span>
            <span className="lineHeading">INTELLECTUAL PROPERTY</span>
          </div>
          <p className="text-justify marginParagraph">
            <span> a) </span>{" "}
            <span className="text-italic">
                Our Intellectual Property.
              </span>
            We and our content suppliers own all intellectual property
            rights in our Platform contents, logos, trademarks (whether
            registered or unregistered) and data. Our IP rights are
            protected by U.S. law and international IP conventions. By
            using our Platform you do not acquire any of our IP rights.
            Nevertheless, you can view and print out this Platform’s
            content for personal use. We reserve all rights that are not
            expressly granted under these Terms or other written
            agreements between you and us.
          </p>
          <p className="text-justify marginParagraph">
            b) <span className="text-italic">Your Submissions.</span>We do
            not claim ownership rights over your content. What’s yours
            remains yours. However, if you upload any content to the
            public areas of our Platform, you state that: (i) you have all
            necessary rights to that content, and (ii) we can display,
            transmit, modify and distribute this content without
            compensation to you. We can use and implement any feedback
            that you voluntarily provide, without compensation to you.
          </p>
          <p className="text-justify marginParagraph">
            c){" "}
            <span className="text-italic"> Copyright Infringement.</span>
            TWe take copyright infringement seriously. Report it to us if
            you see it on our Platform and we will investigate. In
            accordance with the Digital Millennium Copyright Act of 1998,
            the text of which may be found on the U.S. Copyright Office
            website at http://www.copyright.gov/legislation/dmca.pdf, we
            will promptly investigate claims of copyright infringement
            committed using our Platform if such claims are reported to
            info@deeptrading.ai. If you hold copyright or are authorized
            to act on behalf of the copyright holder, you can report
            alleged copyright infringements as follows:
            <div className="ml-3">
              <ol className="romanLowerStyle">
                <li>
                  Identify the copyrighted work that you claim has been
                  infringed.
                </li>
                <li>
                  Identify the material or link you claim is infringing.
                </li>
                <li>
                  Provide your company affiliation (if applicable),
                  mailing address, telephone number, and, if available,
                  email address.
                </li>
                <li>
                  Include both of the following statements in the body of
                  your report:
                  <ul className="dashListStyle">
                    <li>
                      “I hereby state that I have a good faith belief that
                      the disputed use of the copyrighted material is not
                      authorized by the copyright owner, its agent, or the
                      law (e.g., as a fair use)”
                    </li>
                    <li>
                      “I hereby state that the information in this report
                      is accurate and, under penalty of perjury, that I am
                      the owner, or authorized to act on behalf of, the
                      owner, of the copyright or of an exclusive right
                      under the copyright that is allegedly infringed.”
                    </li>
                  </ul>
                </li>
                <li>
                  Provide your full legal name and your electronic or
                  physical signature.
                </li>
              </ol>
            </div>
          </p>
          {/* Section Ends here */}
          {/* Section Starts here */}
          <div className="text-bold">
            <span>4.</span>
            <span className="lineHeading">ACCEPTABLE USE POLICY</span>
          </div>
          By visiting this Platform, you represent and agree that:
          <p className="text-justify marginParagraph">
            a) You are 18 years of age or older. You have a full capacity
            to enter into a legally binding agreement, such as these
            Terms.
          </p>
          <p className="text-justify marginParagraph">
            b) If purchasing anything, you are responsible for ensuring
            your payment method is valid. You will pay as agreed using
            only payment methods which you are authorized to use. You will
            not use false identity.
          </p>
          <p className="text-justify marginParagraph">
            c) You will not let others use your account, except as may be
            explicitly authorized by us. Everything that happens under
            your account is your responsibility. Registering duplicate
            accounts is not allowed.
          </p>
          <p className="text-justify marginParagraph">
            d) If you make a submission, it shall be truthful and not
            misleading. We can terminate any account for writing
            untruthful reviews, comments or other content. We reserve the
            right to edit, reject or erase anything submitted to us
            without prior notice. You will not send spam, anything
            defamatory, vulgar, racist, abusive or hateful.
          </p>
          <p className="text-justify marginParagraph">
            e) You will not use our Platform in connection with any
            sexually explicit material, illegal drugs, promotion of
            alcohol to persons under 21 years of age, pirated computer
            programs, viruses or other harmful code, disclosure of
            anyone's private information without consent, pyramid schemes,
            multilevel-marketing, "get rich quick" offerings,
            encouragement of violence.
          </p>
          <p className="text-justify marginParagraph">
            f) You will ask for our permission before copying anything
            from our Platform for republication.
          </p>
          <p className="text-justify marginParagraph">
            g) You will not use our Platform for anything illegal.
          </p>
          <p className="text-justify marginParagraph">
            h) We reserve the right to terminate any account using our
            sole reasonable discretion and without notice or liability.
          </p>
          <p className="text-justify marginParagraph">
            i) Bots, crawlers, indexers, web spiders, harvesters or any
            similar automatic processes are not allowed on our Platform.
          </p>
          <p className="text-justify marginParagraph">
            j) You will not impede the proper functioning of the Platform.
          </p>
          {/* Section Ends here */}
          {/* Section Starts here */}
          <div className="text-bold">
            <span>5.</span>
            <span className="lineHeading">CONFIDENTIALITY</span>
          </div>
          <p className="text-justify marginParagraph">
            You cannot use or disclose any confidential information
            relating to our business, users, operations and properties for
            any purpose without our express prior written authorization.
            You agree to take all reasonable measures to protect the
            secrecy of and avoid disclosure or use of our confidential
            information.
          </p>
          {/* Section Ends here */}
          {/* Section Starts here */}
          <div className="text-bold">
            <span>6.</span>
            <span className="lineHeading">BREACH OF THESE TERMS</span>
          </div>
          <p className="text-justify marginParagraph">
            If any user violates these Terms or any law, we can, without
            limitation: (i) ban that user from the Platform; (ii) disclose
            the user’s identity to authorities and assist in
            investigations; (iii) delete or moderate the user’s content;
            (iv) take any other action available under law.
          </p>
          {/* Section Ends here */}
          {/* Section Starts here */}
          <div className="text-bold">
            <span>7.</span>
            <span className="lineHeading">
                DISCLAIMER OF WARRANTY; LIMITATION OF LIABILITY
              </span>
          </div>
          By visiting this Platform, you represent and agree that:
          <p className="text-justify marginParagraph">
            a) EVERYTHING WE PROVIDE ON THIS PLATFORM IS ON AN “AS IS”
            BASIS, TO BE RELIED ON AT YOUR OWN RISK. DO YOUR OWN RESEARCH
            BEFORE RELYING ON ANYTHING ON THIS PLATFORM. WE DISCLAIM ALL
            WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUALITY,
            NON-INFRINGEMENT, SAFETY, FREEDOM FROM DEFECTS OR THAT DEFECTS
            WILL BE CORRECTED, UNINTERRUPTED, VIRUS-FREE OR ERROR-FREE
            PERFORMANCE.
          </p>
          <p className="text-justify marginParagraph">
            b) WE ARE NOT LIABLE FOR INDIRECT, SPECIAL, INCIDENTAL OR
            CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS AND PROPERTY
            DAMAGE, EVEN IF WE WERE ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES, NOR SHALL WE BE HELD LIABLE FOR DELAY OR FAILURE IN
            PERFORMANCE RESULTING FROM CAUSES BEYOND OUR REASONABLE
            CONTROL. IN NO EVENT SHALL OUR TOTAL LIABILITY TO YOU FOR ALL
            DAMAGES, LOSSES AND CAUSES OF ACTION EXCEED THE FEES WE
            RECEIVED FROM YOU, IF ANY. SOME JURISDICTIONS DO NOT ALLOW THE
            LIMITATION OF CERTAIN WARRANTIES, SO THE ABOVE LIMITATIONS IN
            THIS SECTION MAY NOT APPLY TO YOU.
          </p>
          {/* Section Ends here */}
          {/* Section Starts here */}
          <div className="text-bold">
            <span>8.</span>
            <span className="lineHeading">INDEMNIFICATION</span>
          </div>
          <p className="text-justify marginParagraph">
            You agree to defend, indemnify and hold harmless our company,
            its officers, directors, employees and agents, from and
            against any and all claims, damages, obligations, losses,
            liabilities, costs or debt, and expenses (including but not
            limited to attorney's fees) arising from: (i) your use of and
            access to the Platform; (ii) your violation of any provision
            of these Terms; (iii) your violation of any third party right,
            including without limitation any copyright, property, or
            privacy right; or (iv) any claim that one of your user
            submissions caused damage to a third party.
          </p>
          {/* Section Ends here */}
          {/* Section Starts here */}
          <div className="text-bold">
            <span>9.</span>
            <span className="lineHeading">
                ARBITRATION; CLASS ACTION WAIVER
              </span>
          </div>
          <p className="text-justify marginParagraph">
            a) <span className="text-italic"> Arbitration.</span>
            Any controversy or claim arising out of or relating to these
            Terms, or the breach thereof, shall be settled by arbitration
            administered by the American Arbitration Association in
            accordance with its Commercial Arbitration Rules and judgment
            on the award rendered by the arbitrator(s) may be entered in
            any court having jurisdiction thereof. The place of
            arbitration shall be Bayonne, NJ. The arbitration shall be
            governed by the laws of the State of New Jersey. Except as may
            be required by law, neither a party nor an arbitrator may
            disclose the existence, content, or results of any arbitration
            hereunder without the prior written consent of both parties.
            <span className="text-bold">
                You understand that this Section means that, by using the
                Platform, you agree to arbitrate, thus, waiving your rights
                to sue in court and have a jury trial.{" "}
              </span>
          </p>
          <p className="text-justify marginParagraph"> b) <span
            className="text-italic"> Class Action Waiver.</span>{" "}
            You acknowledge and agree that you waive your right to
            participate as a plaintiff or class member in any purported
            class action or representative proceeding. Further, unless
            both you and us otherwise agree in writing, the arbitrator may
            not consolidate more than one person's claims, and may not
            otherwise preside over any form of any class or representative
            proceeding.
          </p>
          {/* Section Ends here */}
          {/* Section Starts here */}
          <div className="text-bold">
            <span>10.</span>
            <span className="lineHeading">
                GENERAL
              </span>
          </div>
          <p className="text-justify marginParagraph">
            a) <span className="text-italic"> Communications.</span>
            You agree that we can communicate with you electronically, via SMS, push notifications, email or phone
            calls. All electronic communications shall have the same legal force as if they were in paper form.
            <span className="text-bold">
                You understand that this Section means that, by using the
                Platform, you agree to arbitrate, thus, waiving your rights
                to sue in court and have a jury trial.
              </span>
          </p>
          <p className="text-justify marginParagraph">
            b) <span className="text-italic">Relationship of the Parties.</span>
            You and us are in an independent contractor relationship with respect to each other. That means that
            there is no partnership, joint venture, employer/employee or any similar arrangement.
          </p>
          <p className="text-justify marginParagraph">
            c) <span className="text-italic">Hyperlinks.</span>
            Linking to our Platform is allowed, however, it must always be done in a way that does not adversely
            affect our business or implies some form of association when there is none.
          </p>
          <p className="text-justify marginParagraph">
            d) <span className="text-italic">Severability.</span>
            If any part of these Terms is found to be unenforceable, then only that particular portion, and not the
            entire Terms, will be unenforceable.
          </p>
          <p className="text-justify marginParagraph">
            d) <span className="text-italic">Assignment.</span>
            We have the right, at our sole discretion, to assign or subcontract our rights or obligations outlined
            in these Terms.
          </p>
          <p className="text-justify marginParagraph">
            f) <span className="text-italic">Waiver.</span>
            Our failure to exercise any of our rights under these Terms shall not be considered a waiver to exercise
            them in other instances. No waiver shall be effective unless it is in writing signed by us.
          </p>
          <p className="text-justify marginParagraph">
            g) <span className="text-italic">Prevailing Language.</span>
            If there are any inconsistencies or conflicts between the English original of these Terms and any
            foreign language translation, the English version shall prevail.
          </p>

          {/* Section Starts here */}
          <div className="text-bold">
            <span>11.</span>
            <span className="lineHeading">
              REFUND POLICY
            </span>
          </div>
          <p className="text-justify marginParagraph">
            <span className="text-bold">
              Annual subscriptions are eligible for a full refund within the first 7-days of your payment. Monthly
              subscriptions and one week access payments are eligible for a refund within 24-hours of your payment.
              After the aforementioned refund periods, all sales are final.
            </span>
          </p>
          {/* Section Ends here */}

        </div>
      </div>
    </div>
  </div>
);

export default Terms;
