import React from 'react'
import { useLocation } from 'react-router-dom'

const useParams = (keys = []) => {
  const { search } = useLocation()

  return React.useMemo(() => {
    const urlParams = new URLSearchParams(search.slice(1))
    return keys.map((key) => urlParams.get(key))
  }, [keys, search])
}
export default useParams;
