import React from 'react'
import { PayPalButton } from 'react-paypal-button-v2'

const isProd = process.env.NODE_ENV === 'production';
const key = isProd ? process.env.REACT_APP_PAYPAL_PROD : process.env.REACT_APP_PAYPAL_TEST;

const buttonStyle = { shape: "rect", color: "blue", layout: "horizontal" }

const oneOptions = {
  clientId: key,
  vault: true,
  disableFunding: "credit",
  currency: "USD",
  intent: 'subscription',
};

const subOptions = {
  clientId: key,
  disableFunding: "credit",
  currency: "USD",
}

export const PayPalBtn = ({ amount, createSubscription, onApprove, onReady }) => (
  <PayPalButton
    amount={amount}
    createSubscription={createSubscription}
    onApprove={onApprove}
    onButtonReady={onReady}
    options={oneOptions}
    style={buttonStyle}
  />
);

export const PayPalOneTime = ({ onApprove, createOrder, onReady }) => (
  <PayPalButton
    createOrder={createOrder}
    onSuccess={onApprove}
    onButtonReady={onReady}
    options={subOptions}
    style={buttonStyle}
  />
);


