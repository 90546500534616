import React from 'react'
import { Link } from 'react-router-dom'
import Chart from '../../components/chart/chart'
import EnterPredictions from './enterPredictions'
import TopPredictions from "../../components/reports/topPredictions";
import HomeImg from '../../assets/images/home-image.png';

const Home = () => (
  <div className="bodySec">
    <div className="container">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="homeTxt">
            <Chart ticker="DJI"/>
            <TopPredictions/>
            <EnterPredictions/>
          </div>
        </div>
      </div>

      <div className="row homeInvest">
        <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
          <h4>
            <span>Investment Intelligence at Your Fingertips</span>
          </h4>
          <p>
            It is widely believed, but not common knowledge that Wall Street uses specialized deep learning
            algorithms to predict the stock market. Our mission is to make this capability available to any
            investor who finds value in it.
          </p>
          <p>
            The Deep Index on our home page is free to view so anybody can visualize how our technology performs.
          </p>
          <Link to="/pricing-plans" className="defaultBtn">
            Explore our subscription options today
          </Link>
        </div>

        <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <img src={HomeImg} alt=""/>
        </div>
      </div>
    </div>
  </div>
);

export default Home;
