import Highcharts from "highcharts";

export const chartOptions = {
  chart: {
    type: 'area',
    backgroundColor: "rgba(255, 255, 255, 0)",
    gridLineWidth: 0,
  },
  colors: ['#46cff7', '#ff0000'],
  xAxis:[{
    type: "datetime",
    gridLineColor: '#444444',
    tickInterval: 24 * 3600 * 1000*12,
    labels: {
      formatter: function () {
        return Highcharts.dateFormat('%b %d', new Date(this.value));
      },
      alignTicks: true,
      startOnTick: false,
      endOnTick: false
    },
  }],

  yAxis: [{
    gridLineWidth: 1,//Set this to zero
    gridLineColor: '#444444',
    tickAmount: 3,
    title: {
      text: null
    },
    labels: {
      formatter: function () {
        return this.value //(this.value > 0 ? ' + ' : '') + this.value + '%';
      }
    },
    alignTicks: true,
    startOnTick: false,
    endOnTick: false
    //min: yMin,
    //max: yMax,
  }],

  title: {
    text: ''
  },
  subtitle: {
    text: ''
  },
  plotOptions: {
    series: {
      showInNavigator: true,
      marker: {
          enabled: false
      }
    },
    area: {
      marker: {
        enabled: false,
        symbol: '',
        radius: 1,
      }
    }
  },
  accessibility: {
    enabled: false,
  }
};
