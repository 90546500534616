import React from 'react'
import Confirm from '../../components/confirm/confirm'
import DashboardMenu from '../../components/dashboardMenu/dashboardMenu'
import useApi, {CONTROLLER} from '../../hooks/useApi'
import {dateToString} from '../../utils/date'
import {prettyAmount} from '../../utils/amount'
import Button from '../../components/form/button'
import Spinner from '../../components/spinner/spinner'

const Orders = () => {
  const [id, setId] = React.useState(null);
  const {
    loading: ordersLoading,
    payload: ordersPayload,
    execute: ordersExecute,
  } = useApi(CONTROLLER.ORDERS);
  const {
    loading: cancelLoading,
    execute: cancelExecute,
  } = useApi(CONTROLLER.CANCEL_SUBSCRIPTION);
  const [state, setState] = React.useState({
    show: false,
    title: "",
    message: "",
  });

  React.useEffect(() => {
    ordersExecute();
  }, [ordersExecute]);

  const cancelSubscription = (id) => {
    setId(id);
    setState({
      show: true,
      title: "Cancel Subscription",
      message: "Are you sure you want to cancel your subscription plan?",
    });
  }

  const handleClose = async () => {
    setState({
      show: false,
      title: "",
      message: ""
    });
  }

  const handleOk = async () => {
    setState({
      show: false,
      title: "",
      message: ""
    });
    const {subscription_id} = await cancelExecute({id});
    // --- facebook ---
    window.fbq && window.fbq('track', 'Cancellation', {subscription_id});
    // --- google ---
    window.gtag && window.gtag('event', 'cancel_subscription', {event_category: 'ecommerce', subscription_id});
    await ordersExecute();
  }

  if (ordersLoading) {
    return <Spinner/>
  }

  return (
    <section className="dashboardSec">
      <div className="dashOrdSec">
        <div className="container">
          <DashboardMenu/>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <h2>
                <span>Orders</span>
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="orderTable">
                <table className="table">
                  <thead>
                  <tr>
                    <th valign="middle">Date</th>
                    <th valign="middle">Order</th>
                    <th valign="middle">Plan</th>
                    <th valign="middle">Amount</th>
                    <th valign="middle">Status</th>
                    <th valign="middle">Expires</th>
                    <th valign="middle">Renewal Date</th>
                    <th valign="middle">Trial Expires</th>
                    <th valign="middle">&nbsp;</th>
                  </tr>
                  </thead>
                  <tbody>
                  {ordersPayload?.data?.map((item) => (
                    <tr key={item.OrderId}>
                      <td>
                        {dateToString(item.OrderDate)}
                      </td>
                      <td>
                        #{item.OrderId}
                      </td>
                      <td>
                        {item.Type}
                      </td>
                      <td>
                        ${prettyAmount(item.Amount)}
                      </td>
                      <td style={{textTransform: "capitalize"}} valign="middle">
                        {item.Status}
                      </td>
                      <td>
                        {item.ExpirationDate && dateToString(item.ExpirationDate)}
                      </td>
                      <td>
                        {item.RenewalDate && dateToString(item.RenewalDate)}
                      </td>
                      <td>
                        {item.TrialExpirationDate && dateToString(item.TrialExpirationDate)}
                      </td>
                      <td>
                        {item.isCancelable !== null && (
                          <Button
                            right
                            onClick={() => cancelSubscription(item.OrderId)}
                            disabled={!item.isCancelable}
                            loading={cancelLoading}
                          >
                            Cancel Subscription
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Confirm
        {...state}
        handleOk={handleOk}
        handleClose={handleClose}
      />
    </section>
  );
}

export default Orders;
