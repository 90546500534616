import React from 'react';
import { Button } from 'react-bootstrap'

const Search = ({ onSearch, loading }) => {
  const [search, setSearch] = React.useState("");
  
  const handleSearch = () => onSearch(search);
  const handleChange = (event) => setSearch(event.target.value);
  const handleKeyUp = (event) => event.keyCode === 13 && handleSearch();
  
  return (
    <div style={{ display: "flex" }}>
      <input
        className="form-control"
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        type="text"
        placeholder="Search"
        disabled={loading}
      />
      <Button
        style={{ marginLeft: 10 }}
        onClick={handleSearch}
        className="orangeBtnByPage"
        disabled={loading}
      >
        Search
      </Button>
    </div>
  )
}

export default Search;
