import React from 'react';
import { useHistory } from 'react-router-dom';
import useAOS from '../../hooks/useAOS'
import useApi, { CONTROLLER } from '../../hooks/useApi'
import Popup from "../../components/popup/popup";
import { Form } from '../../components/form/form'
import Field from '../../components/form/field'
import Button from '../../components/form/button'

const ForgotPassword = () => {
  useAOS();
  const { push } = useHistory();
  const { loading, payload, execute } = useApi(CONTROLLER.FORGOT_PASSWORD);
  const [state, setState] = React.useState({
    show: false,
    title: "",
    message: "",
  })
  
  const sendEmail = async ({ email }) => {
    const { success, message } = await execute({ email })
    if (success) {
      setState({
        show: true,
        title: "Action Successful",
        message: "Please check your email to reset password",
      });
    } else {
      setState({
        show: true,
        title: 'Action Failure',
        message,
      });
    }
  }

  const handleClose = () => {
    setState({
      show: false,
      title: "",
      message: "",
    });

    if (payload?.success) {
      push("/");
    }
  };

  return (
    <div className="bodySec storySec">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <Form onSubmit={sendEmail} className="AnimationForm">
              <figcaption className="personal">
                <div className="signUpTabs signIn">
                  <h2 className="text-left">
                    <span>Reset Password</span>
                  </h2>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <Field
                        autoComplete="off"
                        type="text"
                        name="email"
                        label="Your Email"
                        required
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="field submit">
                        <Button loading={loading} right>
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </figcaption>
            </Form>
          </div>
        </div>
      </div>
      <Popup
        {...state}
        handleClose={handleClose}
      />
    </div>
  );
}

export default ForgotPassword;
