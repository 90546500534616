import React from 'react'
import { useHistory } from 'react-router-dom'
import Popup from '../../components/popup/popup'
import { Form } from '../../components/form/form'
import useAOS from '../../hooks/useAOS'
import useApi, { CONTROLLER } from '../../hooks/useApi'
import Step1 from './step1'
import Steps from './steps'
import Step2 from './step2'
import Step3 from './step3'
import Confirm from './confirm'
import Button from '../../components/form/button'

const gAdsSignup = process.env.REACT_APP_GTAG_ID_SIGNUP;

const initialValues = {
  userid: "",
  pass1: "",
  pass2: "",
  firstname: "",
  middlename: "",
  lastname: "",
  companyName: "",
  titleUser: "",
  email: "",
  phone: "",
  add1: "",
  add2: "",
  city: "",
  state: "",
  zipcode: "",
  country: "",
  description: "",
  mailinglist: 1,
  accountStatus: "",
  subs: 1,
  policy: false,
}

const flowSteps = [Step1, Step2, Step3];

const SignUp = () => {
  const action = useAOS();
  const { push } = useHistory();
  const [step, setStep] = React.useState(1);
  const { loading, payload, execute } = useApi(CONTROLLER.SIGNUP);
  const [state, setState] = React.useState({ show: false, title: "", message: "" });

  React.useEffect(() => {
    action();
  }, [action, step]);
  
  const Step = flowSteps[step - 1];

  const handleSignup = async (values) => {
    if (step < 3) {
      setStep((step) => step + 1);
      return;
    }

    if (!values.policy) {
      setState({
        show: true,
        title: "Signing up",
        message: "Please accept DeepTrading.ai Terms of Use and Privacy Policy",
      });
    } else if (values.pass1 !== values.pass2) {
      setState({
        show: true,
        title: "Signing up",
        message: "Passwords not matched",
      });
    } else if (values.pass1.length < 6) {
      setState({
        show: true,
        title: "Signing up",
        message: "Password length is too small",
      });
    } else {
      const { success, message, sqlCode } = await execute({ ...values, password: values.pass2 });
      if (success) {
        // --- twitter ---
        window.twq && window.twq('event', 'tw-o9bb4-od1ef', {});
        // --- facebook ---
        window.fbq && window.fbq('track', 'Lead');
        // --- google ---
        window.gtag && window.gtag('event', 'sign_up', {method: 'internal'});
        window.gtag && window.gtag('event', 'conversion', {send_to: gAdsSignup});
        setState({
          show: true,
          title: "Please Verify Your Account",
          message : "Please check your email and click the link in it to verify your account. Once done, you can log in.",
        });
      } else if (sqlCode === "ER_DUP_ENTRY") {
        setState({
          show: true,
          title: "Signing up",
          message: "Username already exists please choose a different one.",
        });
      } else {
        setState({
          show: true,
          title: "Signing up",
          message,
        });
      }
    }
  };
  
  const handlePrev = () => {
    setStep((step) => step - 1);
  }

  const handleClose = () => {
    setState({
      show: false,
      title: "",
      message: "",
    });

    if (payload?.success) {
      push("/auth/login");
    }
  };

  return (
    <div className="bodySec storySec">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <Form
              className="AnimationForm"
              initialValues={initialValues}
              onSubmit={handleSignup}
            >
              <div className="signUpTabs">
                <h2 className="text-left">
                  <span>Sign Up</span>
                </h2>
                <Steps step={step} />
                <Step />
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="field leftSubmit">
                      {step !== 1 && (
                        <Button
                          type="button"
                          onClick={handlePrev}
                          disabled={loading}
                        >
                          Previous
                        </Button>
                      )}
                      <Button loading={loading} right>
                        {step === 3 ? 'Submit' : 'Next'}
                      </Button>
                    </div>
                  </div>
                </div>
                {step === 3 && (
                  <Confirm />
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
      <Popup
        {...state}
        handleClose={handleClose}
      />
    </div>
  );
}

export default SignUp;
