import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { chartOptions } from './config'

const Chart = ({ close, prediction }) => {
  const options = React.useMemo(() =>({
    ...chartOptions,
    tooltip: {
      pointFormat:
        '<span style="font-size:10px; color:{series.color}">{series.name}:</span> <span style="font-size:10px; font-weight: bold">{point.y}</span>',
      valueDecimals: 2,
      split: true,
      xDateFormat: '%b %e, %Y'
    },
    series: [
      {
        name: "Close",
        type: "area",
        threshold: null,
        yAxis: 0,
        xAxis: 0,
        data: close,
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, "rgba(68, 188, 246, 1)"],
            [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get("rgba")],
          ],
        },
      },
      {
        name: "Prediction",
        yAxis: 0,
        xAxis: 0,
        type: "area",
        threshold: null,
        data: prediction,
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, "rgba(255, 18, 24, 1)"],
            [1, Highcharts.color(Highcharts.getOptions().colors[1]).setOpacity(0).get("rgba")],
          ],
        },
      },
    ],
  }), [close, prediction]);

  return (
    <HighchartsReact highcharts={Highcharts} options={options} />
  )
}

export default Chart;
