import React from 'react'
import { Link } from 'react-router-dom'
import { prettyAmount } from '../../utils/amount'

const PricingPlans = () => (
  <div className="bodySec storySec">
    <div className="container">
      <h2 className="text-left">
        <span>pricing plans</span>
      </h2>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
          <div className="priceBox">
            <div className="priceBox--header">
              <h2>
                <span>Basic</span>
              </h2>
              <p>Save 10% with Yearly Subscription</p>
            </div>
            <div className="priceBox--amount">
              <ul>
                <li>
                  <h6>
                    <span className="priceBox-del">${prettyAmount(99, 0)}</span>
                    <span className="priceBox-off" data-off="40% Off!">${prettyAmount(59, 0)}</span>
                  </h6>
                  <p>per month</p>
                </li>
                <li>
                  <i>OR</i>
                </li>
                <li>
                  <h6>
                    <span className="priceBox-del">${prettyAmount(1069, 0)}</span>
                    <span className="priceBox-off" data-off="40% Off!">${prettyAmount(641, 0)}</span>
                  </h6>
                  <p>Per Year</p>
                </li>
              </ul>
            </div>
            <div className="priceBox--content">
              <ul>
                <li>7 Day Free Trial!</li>
                <li>Choose predictions from a list of 250 stocks</li>
                <li>Daily Predictions</li>
                <li>Two Day Predictions</li>
                <li>Weekly Predictions</li>
                <li>Advanced Deep Learning AI Models</li>
              </ul>
            </div>
            <div className="priceBox--footer">
              <Link to="/cabinet/order/13">
                Subscribe Monthly
              </Link>
              <Link to="/cabinet/order/2">
                Subscribe Yearly
              </Link>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
          <div className="priceBox">
            <div className="priceBox--header">
              <h2>
                <span>Advanced</span>
              </h2>
              <p>Save 10% with Yearly Subscription</p>
            </div>
            <div className="priceBox--amount">
              <ul>
                <li>
                  <h6>
                    <span className="priceBox-del">${prettyAmount(159, 0)}</span>
                    <span className="priceBox-off" data-off="40% Off!">${prettyAmount(95, 0)}</span>
                  </h6>
                  <p>per month</p>
                </li>
                <li>
                  <i>OR</i>
                </li>
                <li>
                  <h6>
                    <span className="priceBox-del">${prettyAmount(1717, 0)}</span>
                    <span className="priceBox-off" data-off="40% Off!">${prettyAmount(1030, 0)}</span>
                  </h6>
                  <p>Per Year</p>
                </li>
              </ul>
            </div>
            <div className="priceBox--content">
              <ul>
                <li>7 Day Free Trial!</li>
                <li>Choose predictions from a list of 250 stocks</li>
                <li>Daily Predictions</li>
                <li>Two Day Predictions</li>
                <li>Weekly Predictions</li>
                <li>Two Week Predictions</li>
                <li>Monthly Predictions</li>
                <li>Two Month Predictions</li>
                <li>Three Month Predictions</li>
                <li>Six Month Predictions</li>
                <li>Yearly Predictions</li>
                <li>Advanced Deep Learning AI Models</li>
              </ul>
            </div>
            <div className="priceBox--footer">
              <Link to="/cabinet/order/14">
                Subscribe Monthly
              </Link>
              <Link to="/cabinet/order/4">
                Subscribe Yearly
              </Link>
            </div>
          </div>
        </div>
        {/*<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">*/}
        {/*  <div className="entPriceBg">*/}
        {/*    <h2>*/}
        {/*      <span>enterprise</span>*/}
        {/*    </h2>*/}
        {/*    <ul>*/}
        {/*      <li>Custom time series data for training</li>*/}
        {/*      <li>Custom model adjustments</li>*/}
        {/*      <li>Advanced Deep Learning AI Models</li>*/}
        {/*      <li>API access to our Deep Learning AI Models</li>*/}
        {/*    </ul>*/}
        {/*    <div className="entMoreInfo">*/}
        {/*      <p>*/}
        {/*        Contact us at <a href="mailto://info@deeptrading.ai">info@deeptrading.ai</a> for pricing details.*/}
        {/*      </p>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
          <div className="priceBox">
            <div className="priceBox--header">
              <h2>
                <span>One Time</span>
              </h2>
              <p>Single One Week Purchase</p>
            </div>
            <div className="priceBox--amount">
              <ul>
                <li>
                  <h6>${prettyAmount(34, 0)}</h6>
                  <p>Basic</p>
                </li>
                <li>
                  <i>OR</i>
                </li>
                <li>
                  <h6>${prettyAmount(49, 0)}</h6>
                  <p>Advanced</p>
                </li>
              </ul>
            </div>
            <div className="priceBox--content"></div>
            <div className="priceBox--footer">
              <Link to="/cabinet/order/5">
                Purchase Basic
              </Link>
              <Link to="/cabinet/order/6">
                Purchase Advanced
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="moreInfo">
            <p>
              For more details contact us at <a href="mailto://info@deeptrading.ai">info@deeptrading.ai</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PricingPlans;
