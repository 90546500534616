import React from 'react'
import { useHistory } from 'react-router-dom'

const EnterPredictions = () => {
  const { push } = useHistory();
  const [ticker, _setTicker] = React.useState("");
  
  const setTicker = React.useCallback((event) => {
    event.preventDefault();
    _setTicker((event.target.value || '').toUpperCase());
  }, [_setTicker])
  
  const onSubmit = (event) => {
    event.preventDefault();
    push(`/cabinet/stock/${ticker}`);
  }
  
  return (
    <div className="nextPredictions">
      <h6>Enter a stock to view next day price predictions</h6>
      <form onSubmit={onSubmit}>
        <div className="field">
          <input
            type="text"
            placeholder="STOCK SYMBOL"
            onChange={setTicker}
            value={ticker}
            size="3" maxLength="5"
          />
          <input
            type="submit"
            value="View"
          />
        </div>
      </form>
    </div>
  )
}

export default EnterPredictions;
