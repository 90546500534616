import React from 'react'
import { useHistory } from 'react-router-dom'
import Chart from './chart/chart'
import classes from './stock.module.scss';
import { cn } from '../../utils/cn'

const Stock = ({ ticker, onRemove, data }) => {
  const { push } = useHistory();

  const acc = React.useMemo(
    () => data?.info?.metrics?.daily_accuracy ?
      Math.round((data?.info?.metrics?.daily_accuracy) * 100) : undefined,
    [data],
  );

  const onClick = React.useCallback(() => {
    push(`/cabinet/stock/${data?.info?.ticker}`);
  }, [data, push]);
  
  return (
    <div className={cn('ui-state-default box ui-sortable-handle', classes.wrapper)}>
      <a
        href="#noting"
        className={classes.link}
        onClick={() => onRemove(ticker)}
      >
        <i className="fa fa-times" aria-hidden="true" />
      </a>
      <div className="stockBg">
        <p className={classes.header} onClick={onClick}>
          {data?.info?.name}
          <br/>
          {data?.info?.ticker}
        </p>
        <Chart
          close={data?.close}
          prediction={data?.prediction}
        />
        {acc && (
          <h6>
            Accuracy: {acc}%
          </h6>
        )}
      </div>
    </div>
  );
};

export default Stock;
