import React from 'react'
import useApi, { CONTROLLER } from '../../hooks/useApi'

const Logout = () => {
  const { execute } = useApi(CONTROLLER.LOGOUT);
  React.useEffect(() => {
    execute();
  }, [execute]);
  return null;
};

export default Logout;
