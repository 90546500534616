import React from 'react'
import { useHistory } from 'react-router-dom'
import Popup from '../../components/popup/popup'
import useParams from '../../hooks/useParams'
import { Form } from '../../components/form/form'
import Field from '../../components/form/field'
import useAOS from '../../hooks/useAOS'
import useApi, { CONTROLLER } from '../../hooks/useApi'
import Button from '../../components/form/button'

const ChangePassword = () => {
  useAOS();
  const { loading, payload, execute } = useApi(CONTROLLER.CHANGE_PASSWORD);
  const { push } = useHistory();
  const [email, userid, code] = useParams(["email", 'userid', 'code']);
  const [state, setState] = React.useState({
    show: false,
    title: "",
    message: "",
  });

  React.useEffect(() => {
    if (!email || !userid || !code) {
      push("/");
    }
  }, [email, userid, code, push]);

  const changePassword = async ({ pass1, pass2 }) => {
    if (pass1 !== pass2) {
      setState({
        show: true,
        title: "Change Password",
        message: "Passwords not matched.",
      });
      return;
    }

    if ((pass1 || '').length < 6) {
      setState({
        show: true,
        title: "Change Password",
        message: "Password is too short.",
      });
      return;
    }
    const { success, message } = await execute({ email, userid, code, pass1, pass2 });

    if (success) {
      setState({
        show: true,
        title: "Action Successful",
        message: "Password has been changed successfully."
      })
    } else {
      setState({
        show: true,
        title: "Action Failure",
        message,
      })
    }
  }

  const handleClose = () => {
    setState({
      show: false,
      title: "",
      message: "",
    });
    if (payload?.success) {
      push("/");
    }
  };

  return (
    <div className="bodySec storySec">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <Form className="AnimationForm" onSubmit={changePassword}>
              <figcaption className="personal">
                <div className="signUpTabs signIn">
                  <h2 className="text-left">
                    <span>Reset Password</span>
                  </h2>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <Field
                        autoComplete="new-password"
                        type="password"
                        name="pass1"
                        required
                        label="New Password"
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <Field
                        autoComplete="new-password"
                        type="password"
                        name="pass2"
                        required
                        label="Repeat Password"
                      />
                    </div>
                    <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                      <Button
                        style={{width: "60%"}}
                        className="saveButton"
                        disabled={loading}
                      >
                        Change Password
                      </Button>
                    </div>
                  </div>
                </div>
              </figcaption>
            </Form>
          </div>
        </div>
      </div>
      <Popup
        {...state}
        handleClose={handleClose}
      />
    </div>
  );
}

export default ChangePassword;
