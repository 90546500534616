import React from 'react'

// const quoraId = process.env.REACT_APP_QUORA_ID;

const useQuora = (email) => {
  // React.useEffect(() => {
  //   /* eslint-disable */
  //   !function(q,e,v,n,t,s){if(q.qp) return; n=q.qp=function(){n.qp?n.qp.apply(n,arguments):n.queue.push(arguments);}; n.queue=[];t=document.createElement(e);t.async=!0;t.src=v; s=document.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s);}(window, 'script', 'https://a.quora.com/qevents.js');
  //   /* eslint-enable */
  //   window.qp.disablePushState = true;
  // }, []);
  //
  // React.useEffect(() => {
  //   if (email) {
  //     window.qp && window.qp('init', quoraId, { email });
  //   }
  // }, [email]);
  
  return React.useCallback((title = 'Purchase', data) => {
    // window.qp && window.qp('track', title, data);
  }, []);
}

export default useQuora;
