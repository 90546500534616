import React from 'react'
import AboutImg from '../../assets/images/nek.jpeg';

const AboutUs = () => (
  <div className="bodySec aboutSec">
    <div className="container">
      <h2>
        <span>About Us</span>
      </h2>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <div className="shadowImg">
            <img style={{height: "380px"}} alt="about" src={AboutImg} />
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
          <div className="aboutTxtBg">
            <h3>
              Nektarios Kalogridis
              <span>Founder and CEO</span>
            </h3>
            <p>
              Nektarios is an AI technology professional with more than twenty years of experience on Wall Street. Having successfully delivered highly
              complex trading systems and applications that utilize Deep Learning AI algorithms for major Investment Banks, he offers unparalleled expertise and
              insight into what it takes to create impactful AI systems. His proven track record in delivering AI projects using Deep Learning has positioned
              him as a thought leader in the investing tech space. With DeepTrading, he makes these advanced AI models accessible to anyone.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AboutUs;
