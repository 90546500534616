import React from 'react'

const FormContext = React.createContext({});

const Form = ({ children, onSubmit, initialValues, ...props }) => {
  const [values, _setValues] = React.useState(initialValues || {});

  const setValues = (name, value) => {
    _setValues((prev) => ({ ...prev, [name]: value }));
  }
  
  const handleSubmit = React.useCallback((event) => {
    event.preventDefault();
    onSubmit(values);
  }, [onSubmit, values]);
  
  const value = React.useMemo(() => ({
    values,
    setValues,
    onSubmit: handleSubmit,
  }), [handleSubmit, values]);

  return (
    <FormContext.Provider value={value}>
      <form onSubmit={handleSubmit} {...props}>
        {children}
      </form>
    </FormContext.Provider>
  );
}

export { Form, FormContext };
