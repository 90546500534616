import React from 'react'
import Field from '../../components/form/field'

const Step3 = () => {
  return (
    <div className="row">
      <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <Field
          autoComplete="new-password"
          type="text"
          name="userid"
          required
          label="Username"
        />
      </div>
      <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <Field
          autoComplete="new-password"
          type="password"
          name="pass1"
          required
          label="Password"
        />
      </div>
      <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <Field
          autoComplete="new-password"
          type="password"
          name="pass2"
          required
          label="Confirm Password"
        />
      </div>
    </div>
  );
}

export default Step3;
