import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Guard from './guard'
import Private from './private'
import Public from './public'
import Faq from '../pages/faq/faq'
import Home from '../pages/home/home'
import Test from '../pages/test/test'
import Terms from '../pages/terms/terms'
import Policy from '../pages/policy/policy'
import AboutUs from '../pages/aboutUs/aboutUs'
import NotFound from '../pages/notFound/notFound'
import OurStory from '../pages/ourStory/ourStory'
import WhatWeDo from '../pages/whatWeDo/whatWeDo'
import ContactUs from '../pages/contactUs/contactUs'
import Unsubscribe from '../pages/unsubscribe/unsubscribe'
import PricingPlans from '../pages/pricingPlans/pricingPlans'
import Verification from '../pages/verification/verification'
import Layout from '../components/layout/layout'
import useApi, { CONTROLLER } from '../hooks/useApi'

const Routes = () => {
  const { execute } = useApi(CONTROLLER.AUTH);

  React.useEffect(() => {
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <Layout>
      <Switch>
        <Route
          path="/"
          exact
          component={Home}
        />
        <Route
          path="/contact-us"
          component={ContactUs}
        />
        <Route
          path="/about-us"
          component={AboutUs}
        />
        <Route
          path="/our-story"
          component={OurStory}
        />
        <Route
          path="/what-we-do"
          component={WhatWeDo}
        />
        <Route
          path="/pricing-plans"
          component={PricingPlans}
        />
        <Route
          path="/policy"
          component={Policy}
        />
        <Route
          path="/terms"
          component={Terms}
        />
        <Route
          path="/faq"
          component={Faq}
        />

        <Route
          path="/verification"
          component={Verification}
        />
        <Route
          path="/unsubscribe"
          component={Unsubscribe}
        />
        <Route
          path="/test"
          component={Test}
        />

        <Guard
          path="/auth"
          ifLoggedIn={false}
          showComponent={Public}
          elseRedirectTo="/cabinet/stocks"
        />

        <Guard
          path="/cabinet"
          ifLoggedIn={true}
          showComponent={Private}
          elseRedirectTo="/auth/login"
        />
        
        <Route
          component={NotFound}
        />
      </Switch>
    </Layout>
  );
};

export default Routes;
