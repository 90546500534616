import React from 'react'
import ContentEditable from 'react-contenteditable'
import './editable.css'

const Editable = ({ text, disabled, handleChange, handleKeyChange, focusOut, ...props }) => (
  <ContentEditable
    html={text} // innerHTML of the editable div
    disabled={disabled} // use true to disable edition
    onChange={handleChange} // handle innerHTML change
    onKeyDown={handleKeyChange}
    onBlur={focusOut}
    className={disabled ? "edit-inactive-class" : "edit-active-class"}
    {...props}
  />
);

export default Editable;
