import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Login from '../pages/login/login'
import SignUp from '../pages/signUp/signUp'
import ForgotPassword from '../pages/forgotPassword/forgotPassword'
import ChangePassword from '../pages/changePassword/changePassword'
import NotFound from '../pages/notFound/notFound'

const Public = () => (
  <Switch>
    <Route path="/auth/login" component={Login} />
    <Route path="/auth/signup" component={SignUp} />
    <Route path="/auth/forgot-password" component={ForgotPassword} />
    <Route path="/auth/change-password" component={ChangePassword} />
    <Route component={NotFound} />
  </Switch>
);

export default Public;
