import React from 'react'
import {useLocation} from 'react-router-dom'

const params = [
  'isAuth',
  'username',
  'paid',
  'advanced',
  'stocks',
  'email',
  'preferences',
];
const defaultValues = {
  isAuth: undefined,
  username: undefined,
  paid: undefined,
  advanced: undefined,
  stocks: null,
  email: undefined,
  preferences: undefined,
};

const StoreContext = React.createContext({});

const Store = ({children}) => {
  const location = useLocation();
  const [store, setStore] = React.useState(defaultValues);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname, location.search, store.username])

  const setResponse = React.useCallback((response) => {
    if (response) {
      const prepare = {};
      for (const name of params) {
        if (![null, undefined].includes(response[name])) {
          if (JSON.stringify(store[name]) !== JSON.stringify(response[name])) {
            prepare[name] = response[name]
          }
        }
      }
      if (Object.keys(prepare).length > 0) {
        if (prepare.isAuth === false) {
          Object.assign(prepare, defaultValues, {isAuth: false});
        }
        setStore((store) => ({...store, ...prepare}));
      }
    }
  }, [store]);

  const value = React.useMemo(() => ({
    store,
    setResponse,
  }), [store, setResponse]);

  return (
    <StoreContext.Provider value={value}>
      {children}
    </StoreContext.Provider>
  );
};

export {Store, StoreContext, defaultValues};
