import React from "react";
import Menu from "../../components/reports/menu";
import TopPredictions from "../../components/reports/topPredictions";

const fields = {
  daily: {
    name: 'Daily',
  },
  weekly: {
    name: 'Weekly',
  },
  monthly: {
    name: 'Monthly',
  },
  three_month: {
    name: 'Three Month',
  },
  // yearly: {
  //   name: 'Yearly',
  // },
};

const Test = () => {
  const [value, setValue] = React.useState('daily');
  return (
    <div className="bodySec aboutSec">
      <div className="container">
        <h2 className="text-center">
          <span>Test</span>
        </h2>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <Menu
              value={value}
              onChange={setValue}
              items={fields}
            />
            <TopPredictions
              name={fields[value].name}
              limit={50}
              param={value}
            />
          </div>
        </div>
      </div>
    </div>
  )
};

export default Test;