export const chartOptions = {
  chart: {
    type: "line",
    spacingTop: 0,
    spacingBottom: 0,
    height: 450,
  },
  title: {
    text: "Dow Industrial Average"
  },
  
  rangeSelector: {
    selected: 2,
    buttons: [
      {
        type: "day",
        count: 5,
        text: "5d",
      },
      {
        type: "day",
        count: 10,
        text: "10d",
      },
      {
        type: "month",
        count: 1,
        text: "1m",
      },
      {
        type: "month",
        count: 3,
        text: "3m",
      },
      {
        type: "month",
        count: 6,
        text: "6m",
      },
      {
        type: "ytd",
        text: "YTD",
      },
      {
        type: "year",
        count: 1,
        text: "1y",
      },
      {
        type: "all",
        text: "All",
      },
    ],
  },
  
  subtitle: {
    text: "Daily Prediction vs Close",
  },
  xAxis: {
    type: 'datetime'
  },
  yAxis: [{
    labels: {
      formatter: function () {
        return this.value; //(this.value > 0 ? ' + ' : '') + this.value + '%';
      },
    },
    allowDecimals: true,
    plotLines: [
      {
        value: 0,
        width: 2,
        color: "silver",
      },
    ],
    height: '80%',
    endOnTick:false,
    tickAmount: 6
  }, {
    labels: {
      formatter: function () {
        return '';
      },
    },
    top: '80%',
    height: '20%',
  }],
  
  plotOptions: {
    series: {
      showInNavigator: true,
      tooltip: {
        headerFormat: '{point.key}',
        xDateFormat: '%A, %b %e, %Y',
      },
      states: {
        inactive: {
          opacity: 1,
        }
      }
    },
  },
  tooltip: {
    pointFormat:
      '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
    valueDecimals: 2,
    split: true,
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: true
  },
  accessibility: {
    enabled: false,
  }
}
