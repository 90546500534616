import React from 'react';
import classes from './spinner.module.scss';
import { cn } from '../../utils/cn'

const Spinner = ({ small, right }) => (
  <div
    className={cn(
    !small && classes.big,
    small && classes.small,
    right && classes.right,
    )}
  />
);

export default Spinner;
