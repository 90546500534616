import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from '../form/button'

const Confirm = ({ show, title, message, handleClose, handleOk }) => (
  <div style={{ background: "#231919" }}>
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: "#2bb3ea" }}>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} small>
          Cancel
        </Button>
        <Button onClick={handleOk} small>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
);

export default Confirm;
