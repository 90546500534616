import React from 'react'
import moment from 'moment'
import HighStock from 'highcharts/highstock'
import HighStockVector from 'highcharts/modules/vector'
import HighStockExporting from 'highcharts/modules/exporting'
import HighStockAccessibility from 'highcharts/modules/accessibility'
import HighchartsReact from 'highcharts-react-official'
import Spinner from '../spinner/spinner'
import Predictions from './predictions'
import {ThemeSetting} from './theme'
import {chartOptions} from './config'
import {dateToString} from '../../utils/date'
import {prettyAmount} from '../../utils/amount'
import {close, fields, future, ghost, vector} from './fields'
import useApi, {CONTROLLER} from '../../hooks/useApi'
import Menu from './menu'
import './fix'

HighStockVector(HighStock);
HighStockExporting(HighStock);
HighStockAccessibility(HighStock);
HighStock.setOptions(ThemeSetting);

const vectorEnabled = process.env.REACT_APP_CHART_VECTOR_ENABLED;
const futureEnabled = process.env.REACT_APP_CHART_FUTURE_ENABLED;

const Chart = ({ticker = "DJI", showPredictions = false}) => {
  const chart = React.useRef();
  const [isFuture, setFuture] = React.useState(false);
  const [isVector, setVector] = React.useState(false);
  const [value, setValue] = React.useState('daily_prediction');
  const apiOptions = React.useMemo((() => ({urlParams: {ticker}})), [ticker])
  const {
    loading: loadingFuture,
    payload: payloadFuture,
    execute: executeFuture
  } = useApi(CONTROLLER.CHART_FUTURE, apiOptions);
  const {
    loading: loadingVector,
    payload: payloadVector,
    execute: executeVector
  } = useApi(CONTROLLER.CHART_VECTOR, apiOptions);
  const {loading, payload: payloadChart, execute: executeChart} = useApi(CONTROLLER.CHART_FULL, apiOptions);
  const {data} = payloadChart || {};

  const description = React.useMemo(() => {
    switch (data?.info?.ticker) {
      case "GSPC":
        return "S&P 500";
      case "DJI":
        return "Dow Industrial Average";
      case "IXIC":
        return "Nasdaq";
      default:
        return data?.info?.ticker;
    }
  }, [data?.info?.ticker]);

  const [minDate, maxDate] = React.useMemo(() => {
    return [
      moment
        .utc(data?.close[data?.close.length - 1][0])
        .add(-1, 'month')
        .unix() * 1000,
      moment
        .utc(data?.info?.predictions?.three_month_prediction_date)
        .unix() * 1000,
    ]
  }, [data?.close, data?.info?.predictions]);

  const clickButton = React.useCallback(() =>
    chart.current?.chart?.rangeSelector?.clickButton(2), []);

  const clickRange = React.useCallback(() =>
    chart.current?.chart?.xAxis[0]?.setExtremes(minDate, maxDate), [maxDate, minDate]);

  const futureToggle = React.useCallback(() => setFuture((value) => value
    ? (setTimeout(clickButton, 100) && false) : (setTimeout(clickRange, 100) && true)), [clickButton, clickRange]);

  const options = React.useMemo(() => ({
    ...chartOptions,
    exporting: {
      enabled: showPredictions,
      buttons: {
        ...(futureEnabled === "true" ? {
          futureButton: {
            className: isFuture ? 'highcharts-future-button highcharts-future-selected' : 'highcharts-future-button',
            text: 'Show Future Predictions',
            x: -200,
            y: 53,
            onclick: futureToggle,
          }
        } : {}),
        ...(vectorEnabled === "true" ? {
          vectorButton: {
            className: isVector ? 'highcharts-future-button highcharts-future-selected' : 'highcharts-future-button',
            text: 'Show Return Data',
            x: (showPredictions && value !== 'all') ? -350 : -10000,
            y: (showPredictions && value !== 'all') ? 53 : -10000,
            onclick: () => setVector(vector => !vector),
          },
        }: {}),
      },
    },
    title: {text: data?.info?.name},
    subtitle: {text: (value === 'all' ? 'All' : fields[value].title) + ' Prediction vs Close'},
    legend: {enabled: true},
    series: [
      {
        ...close,
        data: data?.close,
        visible: true,
        showInLegend: true,
      },
      ...Object
        .keys(fields)
        .filter(field => data && data[field])
        .map(field => ({
            ...fields[field],
            data: data ? data[field] : null,
            visible: (value === field || value === 'all'),
            showInLegend: (value === field || value === 'all'),
          })
        ),
      {
        ...future,
        data: [data?.close[data?.close?.length - 1]]
          .concat(payloadFuture?.data.map(([x, y, title]) => ({x, y, title}))),
        visible: isFuture,
        showInLegend: isFuture,
        tooltip: {
          pointFormatter: function () {
            return this.title ? `${(this.title.replace('_', ' ') + ' PRED').toUpperCase()}: <b>${this.y}<b>` : false;
          },
        },
      },
      {
        ...vector,
        data: (value !== 'all' && payloadVector?.data)
          ? payloadVector?.data[value]?.map(({Date, Direction, Length, IsMatch}) => ({
            x: Date,
            y: 1,
            direction: Direction,
            length: 10 + Length,
            color: IsMatch ? close.color : fields[value].color,
          }))
          : null,
        visible: isVector && showPredictions && value !== 'all',
        tooltip: {
          pointFormat: false,
        },
      },
      {
        ...ghost,
        data: (value !== 'all' && payloadVector?.data)
          ? payloadVector?.data[value]?.map(({Date, IsMatch, PercentReturn}) => ({
            x: Date,
            y: 1,
            color: IsMatch ? close.color : fields[value].color,
            isMatch: IsMatch,
            percentReturn: PercentReturn,
          }))
          : null,
        visible: isVector && showPredictions && value !== 'all',
        tooltip: {
          pointFormatter: function () {
            return `<span style="color:${this.color}">Return</span>: <b>${this.percentReturn}%</b><br /><span style="color:${this.color}">Direction Match</span>: <b>${this.isMatch ? 'Yes' : 'No'}</b>`
          },
        },
      },
    ]
  }), [isVector, data, isFuture, futureToggle, showPredictions, value, payloadFuture, payloadVector]);

  const deep_index = React.useMemo(() =>
    Number.isNaN(data?.info?.deep_index) ? 0 : data?.info?.deep_index, [data?.info?.deep_index]);

  React.useEffect(() => {
    executeChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticker]);

  React.useEffect(() => {
    if (showPredictions) {
      vectorEnabled === "true" && executeVector();
      futureEnabled === "true" && executeFuture();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticker, showPredictions])

  React.useEffect(() => {
    setFuture(false);
    setTimeout(clickButton, 100);
  }, [ticker, showPredictions, value, clickButton]);

  if (loading || loadingVector || loadingFuture) {
    return <Spinner/>
  }

  return (
    <>
      {!showPredictions && (
        <>
          <h2>
            Deep Index
            <span>
              {prettyAmount(Math.round(deep_index), 0)}
            </span>
          </h2>
          <h6>
            Predicted close value of {data?.info?.name} for {dateToString(data?.info?.date, "MMMM Do, YYYY")}
          </h6>
        </>
      )}
      {showPredictions && (
        <>
          <h2>
            {description} prediction
            <span>
              {prettyAmount(deep_index)}
            </span>
          </h2>
          <h6>
            Predicted close value of {data?.info?.name} stock price
            for {dateToString(data?.info?.date, "MMMM Do, YYYY")}
          </h6>
          <Predictions
            predictions={data?.info?.predictions}
          />
          <Menu
            predictions={data}
            value={value}
            onChange={setValue}
          />
        </>
      )}
      <div className="homeGraph">
        <HighchartsReact
          ref={chart}
          highcharts={HighStock}
          constructorType={"stockChart"}
          options={options}
        />
      </div>
    </>
  );
}

export default Chart;
