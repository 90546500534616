import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { StoreContext } from '../store'

const Guard = ({ ifLoggedIn, elseRedirectTo, showComponent, path, location, computedMatch }) => {
  const { store: { isAuth } } = React.useContext(StoreContext);
  if (isAuth === ifLoggedIn) {
    return <Route component={showComponent} computedMatch={computedMatch} />;
  }
  if (isAuth === !ifLoggedIn) {
    if (isAuth === false && path === "/cabinet" && location.pathname !== '/cabinet/logout') {
      localStorage.setItem('redirect', location.pathname);
    } else {
      const pathname = localStorage.getItem('redirect');
      if (pathname) {
        localStorage.removeItem('redirect');
        return <Redirect to={{ pathname }}/>
      }
    }
    return <Redirect to={{ pathname: elseRedirectTo }} />;
  }
  return null;
};

export default Guard;
