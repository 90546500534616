import React from 'react';
import useApi, { CONTROLLER } from '../../hooks/useApi'
import useParams from '../../hooks/useParams'
import Spinner from '../../components/spinner/spinner'

const Unsubscribe = () => {
  const [ email ] = useParams(['email']);
  const { loading, payload, execute } = useApi(CONTROLLER.UNSUBSCRIBE);

  React.useEffect(() => {
    execute({ email })
  }, [email, execute]);
  
  if (loading) {
    return <Spinner />
  }

  return (
    <div className="bodySec whatWeDoSec">
      <div className="container">
        <div className="row notfound">
          <div className="col-12">
            <div className="whatWeDoTxt">
              <h2 style={{textAlign: "center"}}>
                <span>
                  Email Unsubscribe
                </span>
              </h2>
              <p>
                {payload?.message}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Unsubscribe;
