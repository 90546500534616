import React from 'react';
import moment from "moment";
import {Button} from "react-bootstrap";
import Grid from "../../components/grid/grid";
import DashboardMenu from "../../components/dashboardMenu/dashboardMenu";
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import useApi, {CONTROLLER} from "../../hooks/useApi";
import Field from "../../components/form/field";
import useWidth from "../../hooks/useWidth";
import {StoreContext} from "../../store";
import {useHistory} from "react-router-dom";
// import ChooseColumns from './chooseColumns/chooseColumns';

const names = [
  "Date",
  "Ticker",
  "Name",
  "Close",

  "DailyPricePrediction",
  "DailyPredictionDate",
  "DailyReturn",
  "DailyReturnPrediction",
  "DailyPercentDelta",
  "DailyAccuracy",

  "TwoDayPricePrediction",
  "TwoDayPredictionDate",
  "TwoDayReturn",
  "TwoDayReturnPrediction",
  "TwoDayPercentDelta",
  "TwoDayAccuracy",

  "WeeklyPricePrediction",
  "WeeklyPredictionDate",
  "WeeklyReturn",
  "WeeklyReturnPrediction",
  "WeeklyPercentDelta",
  "WeeklyAccuracy",

  "TwoWeekPricePrediction",
  "TwoWeekPredictionDate",
  "TwoWeekReturn",
  "TwoWeekReturnPrediction",
  "TwoWeekPercentDelta",
  "TwoWeekAccuracy",

  "MonthlyPricePrediction",
  "MonthlyPredictionDate",
  "MonthlyReturn",
  "MonthlyReturnPrediction",
  "MonthlyPercentDelta",
  "MonthlyAccuracy",

  "TwoMonthPricePrediction",
  "TwoMonthPredictionDate",
  "TwoMonthReturn",
  "TwoMonthReturnPrediction",
  "TwoMonthPercentDelta",
  "TwoMonthAccuracy",

  "ThreeMonthPricePrediction",
  "ThreeMonthPredictionDate",
  "ThreeMonthReturn",
  "ThreeMonthReturnPrediction",
  "ThreeMonthPercentDelta",
  "ThreeMonthAccuracy",

  "SixMonthPricePrediction",
  "SixMonthPredictionDate",
  "SixMonthReturn",
  "SixMonthReturnPrediction",
  "SixMonthPercentDelta",
  "SixMonthAccuracy",

  "YearlyPricePrediction",
  "YearlyPredictionDate",
  "YearlyReturn",
  "YearlyReturnPrediction",
  "YearlyPercentDelta",
  "YearlyAccuracy",

  "Open",
  "Low",
  "High",
  "Volume"


];

const types = (name) => ['Name', 'Ticker'].includes(name) ? 'string' : name.endsWith('Date') ? 'date' : 'number';

const params = (name, tickers, onSortInfo) => {
  const type = types(name);
  const result = {
    name,
    type,
    minWidth: 200,
    textAlign: 'center',
    headerAlign: 'center',
    // headerDOMProps: {
    //   onClick: (event, props) => onSortInfo(event, props.name),
    // },
  };
  if (name === 'Ticker') {
    return {
      ...result,
      type: 'select',
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        dataSource: (tickers || []).map(ticker => ({id: ticker, label: ticker})),
      }
    }
  }
  if (name === 'Name') {
    return {...result, textAlign: 'start'}
  }
  if (type === 'number') {
    return {
      ...result,
      filterEditor: NumberFilter,
      filterEditorProps: (props, {index}) => ({placeholder: index === 1 ? 'Max' : 'Min', step: 0.01}),
    };
  }
  if (type === 'date') {
    return {
      ...result,
      dateFormat: 'YYYY-MM-DD',
      filterEditor: DateFilter,
      render: ({value, cellProps: {dateFormat}}) => value && moment.utc(value).format(dateFormat),
    };
  }
  return result;
}

const Search = () => {
  const ref = React.useRef();
  const { push } = useHistory();
  const [gridRef, setGridRef] = React.useState();
  // const [modal, setModal] = React.useState(true);
  const [align, setAlign] = React.useState(false);
  const {payload, execute} = useApi(CONTROLLER.TICKERS_LOAD);
  const {store: {paid, advanced}} = React.useContext(StoreContext);

  const [width, setRef] = useWidth();
  const [rowWidth, setRowRef] = useWidth();
  
  React.useEffect(() => {
    if (paid === false) {
      push("/pricing-plans");
    }
  }, [paid, push]);

  React.useEffect(() => {
    setRef(document.querySelector('section header'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!payload) {
      execute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  const columns = React.useMemo(
    () => {
      const paid_list = ['Weekly', 'TwoDay', 'Daily'];
      const advanced_list = ['Yearly', 'SixMonth', 'ThreeMonth', 'TwoMonth', 'Monthly', 'TwoWeek'];
      let temp = [...names];
      if (!advanced) {
        temp = temp.filter(name => !advanced_list.find(period => name.startsWith(period)));
      }
      if (!paid) {
        temp = temp.filter(name => !paid_list.find(period => name.startsWith(period)));
      }
      return temp.map(name => params(name, (payload || {}).data))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [payload, paid, advanced],
  );

  const defaultSortInfo = React.useMemo(() => [{
    dir: -1,
    name: "Date",
  }, {
    dir: 1,
    name: "Ticker",
  }], []);

  const defaultFilterValue = React.useMemo(() => columns.map(({name, type}) => ({
    name,
    type,
    operator: type === 'string' ? 'contains' : type === 'select' ? 'inlist' : 'inrange',
    value: null,
  })), [columns]);

  const style = React.useMemo(() => {
    const margin = Math.round(-(width - rowWidth) / 2);
    return {marginLeft: margin, marginRight: margin, minHeight: 700, zIndex: 100};
  }, [rowWidth, width]);

  return (
    <div className="stockSec">
      <div className="container">
        <DashboardMenu/>
        <div className="row" ref={setRowRef}>
          <div className="col-6">
            <h2>
              <span>Data Search</span>
            </h2>
          </div>
          <div className="col-6 text-right">
            <Field
              type="checkbox"
              name="align"
              value={align}
              label="Align Predictions"
              onChange={setAlign}
            />
            <Button
              style={{marginLeft: 30}}
              onClick={() => ref.current.save()}
              className="orangeBtnByPage"
              disabled={!gridRef?.current}
            >
              Save Table State
            </Button>
            {/*<Button*/}
            {/*  style={{marginLeft: 15}}*/}
            {/*  onClick={() => ref.current.reset()}*/}
            {/*  className="orangeBtnByPage"*/}
            {/*  disabled={!gridRef?.current}*/}
            {/*>*/}
            {/*  Reset Table State*/}
            {/*</Button>*/}
            <Button
              style={{marginLeft: 15}}
              onClick={() => gridRef.current.clearAllFilters()}
              className="orangeBtnByPage"
              disabled={!gridRef?.current}
            >
              Clear All Filters
            </Button>
            {/*<Button*/}
            {/*  style={{marginLeft: 15}}*/}
            {/*  onClick={() => gridRef.current.clearAllFilters()}*/}
            {/*  className="orangeBtnByPage"*/}
            {/*  disabled={!gridRef?.current}*/}
            {/*>*/}
            {/*  Reset All Filters*/}
            {/*</Button>*/}
            {/*<Button*/}
            {/*  style={{marginLeft: 15}}*/}
            {/*  onClick={() => setModal(true)}*/}
            {/*  className="orangeBtnByPage"*/}
            {/*  disabled={!gridRef?.current}*/}
            {/*>*/}
            {/*  Choose Columns*/}
            {/*</Button>*/}
            {/*{modal && (*/}
            {/*  <ChooseColumns*/}
            {/*    handleClose={() => setModal(false)}*/}
            {/*    show={modal}*/}
            {/*  />*/}
            {/*)}*/}
          </div>
        </div>
        <div className="row">
          <Grid
            ref={ref}
            preference="dataSearch"
            report={align ? "dataSearchAlign" : "dataSearch"}

            enableFiltering
            defaultFilterValue={defaultFilterValue}
            enableColumnFilterContextMenu={false}

            idProperty="Ticker"
            className="selectStockModal"
            columns={columns}

            rowHeight={35}

            sortable
            defaultSortInfo={defaultSortInfo}

            pagination="remote"

            style={style}

            autoHide
            onReady={setGridRef}

            reorderColumns
            showColumnMenuTool
            showColumnMenuSortOptions={false}
            showColumnMenuGroupOptions={false}
            showColumnMenuFilterOptions={false}
            showColumnMenuLockOptions={false}

            scrollProps={{
              autoHide: false,
              scrollThumbWidth: 15,
              scrollThumbOverWidth: 20,
            }}
          />
        </div>
      </div>
    </div>
  )
};

export default Search;
