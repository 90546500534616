import React from "react";
import jQuery from "jquery";
import axios from "axios";
import Editable from "../../components/editable/editable";
import Popup from "../../components/popup/popup";
import "./profile.css";
import DashboardMenu from '../../components/dashboardMenu/dashboardMenu'
import Spinner from '../../components/spinner/spinner'

// todo: need refactor !!!
class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: false,
      edit: {
        Email: true,
        FirstName: true,
        LastName: true,
        MiddleName: true,
        Company: true,
        Title: true,
        PhoneNumber: true,
        StreetAddress1: true,
        City: true,
        State: true,
        ZipCode: true,
        Country: true,
        Description: true,
      },
      d: false,
      show: false,
      message: "",
      title: "",
    };
  }

  async componentDidMount() {
    let response = await axios.get("/api/profile");
    if (response.data.profile) {
      this.setState({
        profile: response.data.profile,
      });
    }
  }

  handleChange = (e, name) => {
    let temp = this.state.profile;
    temp[name] = e.target.value;
    this.setState({
      profile: temp,
    });
  };
  handleKeyChange = async (e, name) => {
    if (e.keyCode === 13) {
      if(name === "Description"){
        return
      }
      let temp = this.state.edit;
      const filtered = Object.assign(
        ...Object.keys(temp).map((k) => ({ [k]: true }))
      );
      this.setState({
        edit: filtered,
      });
      return;
    }
    if (e.keyCode === 27) {
      let response = await axios.get("/api/profile");
      let tempState = this.state.profile;
      tempState[name] = response.data.profile[name];
      this.setState({
        profile: tempState,
      });
      let temp = this.state.edit;
      const filtered = Object.assign(
        ...Object.keys(temp).map((k) => ({ [k]: true }))
      );
      this.setState({
        edit: filtered,
      });
      return;
    }
  };
  changeEditable = (name) => {
    let temp = this.state.edit;
    const filtered = Object.assign(
      ...Object.keys(temp).map((k) => ({ [k]: true }))
    );
    filtered[name] = false;
    this.setState({
      edit: filtered,
    });
    if(name === "Description"){
      return
    }

    setTimeout(() => {
      jQuery(".edit-active-class").focus();
      let text = jQuery(".edit-active-class").text();
      const profileValue = this.state.profile;
      profileValue[name] = "";
      this.setState({
        profile: profileValue,
      });
      profileValue[name] = text;
      this.setState({
        profile: profileValue,
      });
    }, 100);
  };

  focusOut = () => {
    let temp = this.state.edit;
    const filtered = Object.assign(
      ...Object.keys(temp).map((k) => ({ [k]: true }))
    );
    this.setState({
      edit: filtered,
    });
  };

  saveProfile = async () => {
    const response = await axios.post("/api/update-profile", {
      ...this.state.profile,
    });
    if (response.data.success) {
      this.setState({
        show: true,
        title: "Profile Settings",
        message: `Your profile has been updated successfully.`,
        s: false,
      });
    } else {
      this.setState({
        show: true,
        title: "Profile Settings",
        message: response.data.message,
        s: false,
      });
    }
  };
  handleClose = () => {
    this.setState({
      show: false,
      title: "",
      message: "",
    });
  };

  render() {
    const { profile, show, title, message } = this.state;
    if (!profile) {
      return <Spinner />
    }
    return (
      <section className="dashboardSec">
        <div className="dashBeditSec">
          <div className="container">
            <DashboardMenu />
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <h2>
                  <span>Profile</span>
                </h2>
                <div className="dashEdit">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="left-label-profile">
                        <span className="labeleditprofile">Username: &nbsp;</span>
                        <span style={{ color: "#fff" }}>
                          {profile.UserId}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="left-label-profile">
                        <span className="labeleditprofile">Email: &nbsp;</span>
                        <span style={{ color: "#fff" }}>
                          <Editable
                            handleKeyChange={(e) =>
                              this.handleKeyChange(e, "Email")
                            }
                            focusOut={this.focusOut}
                            disabled={this.state.edit.Email}
                            handleChange={(e) => this.handleChange(e, "Email")}
                            text={profile.Email}
                          />
                        </span>
                      </div>
                      <div className="right-label-profile">
                        <i
                          onClick={() => this.changeEditable("Email")}
                          className={
                            !this.state.edit.Email
                              ? "fa fa-pencil-square-o active-edit-box"
                              : "fa fa-pencil-square-o inactive-edit-box"
                          }
                          style={{ cursor: "pointer" }}
                          aria-hidden="true"
                        >
                          &nbsp;
                          <span className="edit-button-profile"> Edit </span>
                        </i>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="left-label-profile">
                        <span className="labeleditprofile">
                          First name: &nbsp;
                        </span>
                        <span style={{ color: "#fff" }}>
                          <Editable
                            handleKeyChange={(e) =>
                              this.handleKeyChange(e, "FirstName")
                            }
                            focusOut={this.focusOut}
                            disabled={this.state.edit.FirstName}
                            handleChange={(e) =>
                              this.handleChange(e, "FirstName")
                            }
                            text={profile.FirstName}
                          />
                        </span>
                      </div>
                      <div className="right-label-profile">
                        <i
                          onClick={() => this.changeEditable("FirstName")}
                          className={
                            !this.state.edit.FirstName
                              ? "fa fa-pencil-square-o active-edit-box"
                              : "fa fa-pencil-square-o inactive-edit-box"
                          }
                          style={{ cursor: "pointer" }}
                          aria-hidden="true"
                        >
                          &nbsp;
                          <span className="edit-button-profile"> Edit </span>
                        </i>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="left-label-profile">
                        <span className="labeleditprofile">
                          Middle name: &nbsp;
                        </span>
                        <span style={{ color: "#fff" }}>
                          <Editable
                            handleKeyChange={(e) =>
                              this.handleKeyChange(e, "MiddleName")
                            }
                            focusOut={this.focusOut}
                            disabled={this.state.edit.MiddleName}
                            handleChange={(e) =>
                              this.handleChange(e, "MiddleName")
                            }
                            text={profile.MiddleName}
                          />
                        </span>
                      </div>
                      <div className="right-label-profile">
                        <i
                          onClick={() => this.changeEditable("MiddleName")}
                          className={
                            !this.state.edit.MiddleName
                              ? "fa fa-pencil-square-o active-edit-box"
                              : "fa fa-pencil-square-o inactive-edit-box"
                          }
                          style={{ cursor: "pointer" }}
                          aria-hidden="true"
                        >
                          &nbsp;
                          <span className="edit-button-profile"> Edit </span>
                        </i>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="left-label-profile">
                        <span className="labeleditprofile">
                          Last name: &nbsp;
                        </span>
                        <span style={{ color: "#fff" }}>
                          <Editable
                            handleKeyChange={(e) =>
                              this.handleKeyChange(e, "LastName")
                            }
                            focusOut={this.focusOut}
                            disabled={this.state.edit.LastName}
                            handleChange={(e) =>
                              this.handleChange(e, "LastName")
                            }
                            text={profile.LastName}
                          />
                        </span>
                      </div>
                      <div className="right-label-profile">
                        <i
                          onClick={() => this.changeEditable("LastName")}
                          style={{ cursor: "pointer" }}
                          className={
                            !this.state.edit.LastName
                              ? "fa fa-pencil-square-o active-edit-box"
                              : "fa fa-pencil-square-o inactive-edit-box"
                          }
                          aria-hidden="true"
                        >
                          &nbsp;
                          <span className="edit-button-profile"> Edit </span>
                        </i>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="left-label-profile">
                        <span className="labeleditprofile">
                          Company: &nbsp;
                        </span>
                        <span style={{ color: "#fff" }}>
                          <Editable
                            handleKeyChange={(e) =>
                              this.handleKeyChange(e, "Company")
                            }
                            focusOut={this.focusOut}
                            disabled={this.state.edit.Company}
                            handleChange={(e) =>
                              this.handleChange(e, "Company")
                            }
                            text={profile.Company}
                          />
                        </span>
                      </div>
                      <div className="right-label-profile">
                        <i
                          onClick={() => this.changeEditable("Company")}
                          style={{ cursor: "pointer" }}
                          className={
                            !this.state.edit.Company
                              ? "fa fa-pencil-square-o active-edit-box"
                              : "fa fa-pencil-square-o inactive-edit-box"
                          }
                          aria-hidden="true"
                        >
                          &nbsp;
                          <span className="edit-button-profile"> Edit </span>
                        </i>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="left-label-profile">
                        <span className="labeleditprofile">Title: &nbsp;</span>
                        <span style={{ color: "#fff" }}>
                          <Editable
                            handleKeyChange={(e) =>
                              this.handleKeyChange(e, "Title")
                            }
                            focusOut={this.focusOut}
                            disabled={this.state.edit.Title}
                            handleChange={(e) => this.handleChange(e, "Title")}
                            text={profile.Title}
                          />
                        </span>
                      </div>
                      <div className="right-label-profile">
                        <i
                          onClick={() => this.changeEditable("Title")}
                          style={{ cursor: "pointer" }}
                          className={
                            !this.state.edit.Title
                              ? "fa fa-pencil-square-o active-edit-box"
                              : "fa fa-pencil-square-o inactive-edit-box"
                          }
                          aria-hidden="true"
                        >
                          &nbsp;
                          <span className="edit-button-profile"> Edit </span>
                        </i>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="left-label-profile">
                        <span className="labeleditprofile">
                          Phone number: &nbsp;
                        </span>
                        <span style={{ color: "#fff" }}>
                          <Editable
                            handleKeyChange={(e) =>
                              this.handleKeyChange(e, "PhoneNumber")
                            }
                            focusOut={this.focusOut}
                            disabled={this.state.edit.PhoneNumber}
                            handleChange={(e) =>
                              this.handleChange(e, "PhoneNumber")
                            }
                            text={profile.PhoneNumber}
                          />
                        </span>
                      </div>
                      <div className="right-label-profile">
                        <i
                          onClick={() => this.changeEditable("PhoneNumber")}
                          style={{ cursor: "pointer" }}
                          className={
                            !this.state.edit.PhoneNumber
                              ? "fa fa-pencil-square-o active-edit-box"
                              : "fa fa-pencil-square-o inactive-edit-box"
                          }
                          aria-hidden="true"
                        >
                          &nbsp;
                          <span className="edit-button-profile"> Edit </span>
                        </i>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="left-label-profile">
                        <span className="labeleditprofile">
                          Address: &nbsp;
                        </span>
                        <span style={{ color: "#fff" }}>
                          <Editable
                            handleKeyChange={(e) =>
                              this.handleKeyChange(e, "StreetAddress1")
                            }
                            focusOut={this.focusOut}
                            disabled={this.state.edit.StreetAddress1}
                            handleChange={(e) =>
                              this.handleChange(e, "StreetAddress1")
                            }
                            text={profile.StreetAddress1}
                          />
                        </span>
                      </div>
                      <div className="right-label-profile">
                        <i
                          onClick={() => this.changeEditable("StreetAddress1")}
                          style={{ cursor: "pointer" }}
                          className={
                            !this.state.edit.StreetAddress1
                              ? "fa fa-pencil-square-o active-edit-box"
                              : "fa fa-pencil-square-o inactive-edit-box"
                          }
                          aria-hidden="true"
                        >
                          &nbsp;
                          <span className="edit-button-profile"> Edit </span>
                        </i>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="left-label-profile">
                        <span className="labeleditprofile"> City: &nbsp; </span>
                        <span style={{ color: "#fff" }}>
                          <Editable
                            handleKeyChange={(e) =>
                              this.handleKeyChange(e, "City")
                            }
                            focusOut={this.focusOut}
                            disabled={this.state.edit.City}
                            handleChange={(e) => this.handleChange(e, "City")}
                            text={profile.City}
                          />
                        </span>
                      </div>
                      <div className="right-label-profile">
                        <i
                          onClick={() => this.changeEditable("City")}
                          style={{ cursor: "pointer" }}
                          className={
                            !this.state.edit.City
                              ? "fa fa-pencil-square-o active-edit-box"
                              : "fa fa-pencil-square-o inactive-edit-box"
                          }
                          aria-hidden="true"
                        >
                          &nbsp;
                          <span className="edit-button-profile"> Edit </span>
                        </i>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="left-label-profile">
                        <span className="labeleditprofile">State: &nbsp;</span>
                        <span style={{ color: "#fff" }}>
                          <Editable
                            handleKeyChange={(e) =>
                              this.handleKeyChange(e, "State")
                            }
                            focusOut={this.focusOut}
                            disabled={this.state.edit.State}
                            handleChange={(e) => this.handleChange(e, "State")}
                            text={profile.State}
                          />
                        </span>
                      </div>
                      <div className="right-label-profile">
                        <i
                          onClick={() => this.changeEditable("State")}
                          style={{ cursor: "pointer" }}
                          className={
                            !this.state.edit.State
                              ? "fa fa-pencil-square-o active-edit-box"
                              : "fa fa-pencil-square-o inactive-edit-box"
                          }
                          aria-hidden="true"
                        >
                          &nbsp;
                          <span className="edit-button-profile"> Edit </span>
                        </i>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="left-label-profile">
                        <span className="labeleditprofile">
                          ZipCode: &nbsp;
                        </span>
                        <span style={{ color: "#fff" }}>
                          <Editable
                            handleKeyChange={(e) =>
                              this.handleKeyChange(e, "ZipCode")
                            }
                            focusOut={this.focusOut}
                            disabled={this.state.edit.ZipCode}
                            handleChange={(e) =>
                              this.handleChange(e, "ZipCode")
                            }
                            text={profile.ZipCode}
                          />
                        </span>
                      </div>
                      <div className="right-label-profile">
                        <i
                          onClick={() => this.changeEditable("ZipCode")}
                          style={{ cursor: "pointer" }}
                          className={
                            !this.state.edit.ZipCode
                              ? "fa fa-pencil-square-o active-edit-box"
                              : "fa fa-pencil-square-o inactive-edit-box"
                          }
                          aria-hidden="true"
                        >
                          &nbsp;
                          <span className="edit-button-profile"> Edit </span>
                        </i>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="left-label-profile">
                        <span className="labeleditprofile">
                          Country: &nbsp;
                        </span>
                        <span style={{ color: "#fff" }}>
                          <Editable
                            handleKeyChange={(e) =>
                              this.handleKeyChange(e, "Country")
                            }
                            focusOut={this.focusOut}
                            disabled={this.state.edit.Country}
                            handleChange={(e) =>
                              this.handleChange(e, "Country")
                            }
                            text={profile.Country}
                          />
                        </span>
                      </div>
                      <div className="right-label-profile">
                        <i
                          onClick={() => this.changeEditable("Country")}
                          style={{ cursor: "pointer" }}
                          className={
                            !this.state.edit.Country
                              ? "fa fa-pencil-square-o active-edit-box"
                              : "fa fa-pencil-square-o inactive-edit-box"
                          }
                          aria-hidden="true"
                        >
                          &nbsp;
                          <span className="edit-button-profile"> Edit </span>
                        </i>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="left-label-profile">
                        <span className="labeleditprofile">
                          Description: &nbsp;
                        </span>
                        <span style={{ color: "#fff", lineHeight: "28px" }}>
                          <Editable
                            handleKeyChange={(e) =>
                              this.handleKeyChange(e, "Description")
                            }
                            focusOut={this.focusOut}
                            disabled={this.state.edit.Description}
                            handleChange={(e) =>
                              this.handleChange(e, "Description")
                            }
                            tagName='article'
                            text={profile.Description}
                            style= {{whiteSpace: "pre-line"}}
                          />
                        </span>
                      </div>
                      <div style={{position: "absolute", right: "1.3%"}} className="right-label-profile">
                        <i
                          onClick={() => this.changeEditable("Description")}
                          style={{ cursor: "pointer" }}
                          className={
                            !this.state.edit.Description
                              ? "fa fa-pencil-square-o active-edit-box"
                              : "fa fa-pencil-square-o inactive-edit-box"
                          }
                          aria-hidden="true"
                        >
                          &nbsp;
                          <span className="edit-button-profile"> Edit </span>
                        </i>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: 20 }} className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <button
                        onClick={this.saveProfile}
                        className="saveButton"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Popup
          show={show}
          title={title}
          message={message}
          handleClose={this.handleClose}
        />
      </section>
    );
  }
}

export default Profile;
