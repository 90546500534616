import React from 'react'
import { Modal } from 'react-bootstrap'
import { StoreContext } from '../../../store'
import useApi, { CONTROLLER } from '../../../hooks/useApi'
import List from './list'
import Search from './search'
import Button from '../../../components/form/button'
import './select.css'

const Select = ({ handleClose, show }) => {
  const [data, setData] = React.useState([]);
  const { loading: loadingSearch, payload, execute: apiSearch } = useApi(CONTROLLER.SEARCH);
  const { loading: loadingSave, execute: apiSave } = useApi(CONTROLLER.PREF_SAVE, {urlParams: {name: 'stocks'}});
  const { store: { preferences: { stocks } = {} } } = React.useContext(StoreContext);

  React.useEffect(() => {
    if (show) {
      setData(stocks);
    }
  }, [show, stocks]);

  React.useEffect(() => {
    apiSearch()
  }, [apiSearch]);
  
  const isDiff = React.useMemo(() => {
    return JSON.stringify(data) === JSON.stringify(stocks);
  }, [data, stocks]);
  
  const handleSave = async () => {
    await apiSave(data);
    handleClose();
  };

  const onSearch = React.useCallback((search) => apiSearch({ search }), [apiSearch]);
  const loading = React.useMemo(() => loadingSearch || loadingSave, [loadingSave, loadingSearch]);
  
  return (
    <div style={{ background: "#231919" }}>
      <Modal
        show={show}
        className="stockModalBlock"
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title style={{ color: "#2bb3ea" }}>
            Select Stocks
          </Modal.Title>
          <Search
            onSearch={onSearch}
            loading={loading}
          />
        </Modal.Header>
        <Modal.Body className="stockModalBody">
          {loadingSearch && (
            <div
              style={{margin: "auto"}}
              className="smallLoader"
            />
          )}
          {!loadingSearch && (
            <List
              selected={data}
              list={payload?.data}
              handleSelect={setData}
              disabled={loadingSave}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleSave}
            loading={loadingSave}
            disabled={isDiff}
            small
          >
            Save
          </Button>
          <Button onClick={handleClose} small>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Select;
