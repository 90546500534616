import React from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'
import '@inovua/reactdatagrid-enterprise/index.css'
import '@inovua/reactdatagrid-enterprise/theme/default-dark.css'
import useApi, {CONTROLLER} from "../../hooks/useApi";
import useStorage from "./useStorage";

const getHeaderName = (name) => (name || '').replace(/([A-Z]+)/g, ' $1').trim();

const Grid = React.forwardRef((props, ref) => {
  const {loading, execute} = useApi(CONTROLLER.REPORTS);

  const defColumns = React.useMemo(
    () => props.columns.map(
      (column) =>
        typeof column === 'string'
          ? ({
            name: column,
            header: getHeaderName(column),
            defaultFlex: 1,
            headerAlign: 'center',
          })
          : ({
            header: getHeaderName(column.name),
            defaultFlex: 1,
            headerAlign: 'center',
            ...column,
          })
    ),
    [props.columns],
  );

  const {
    columns,
    sortInfo,
    columnOrder,
    filterValue,
    onColumnResize,
    onColumnOrderChange,
    onFilterValueChange,
    onColumnVisibleChange,
  } = useStorage(
    ref,
    props.preference,
    {
      columns: defColumns,
      defaultSortInfo: props.defaultSortInfo,
    },
  );

  const loadReport = React.useCallback(
    async ({
             skip,
             limit,
             sortInfo,
             groupBy,
             filterValue,
           }) => {
      const response = await execute(
        {
          ...props.reportParams,
          skip,
          limit,
          sortInfo,
          groupBy,
          filterValue
        },
        {
          urlPath: props.report,
        },
      );
      if (props.afterLoad) {
        props.afterLoad(response);
      }
      return {
        data: props.prepareData ? props.prepareData(response.data) || [] : response.data,
        count: response["data-total-count"],
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.report, props.prepareData, props.reportParams],
  );

  const dataSource = React.useMemo(
    () => props.report ? loadReport : (props.prepareData ? props.prepareData(props.dataSource) || [] : props.dataSource),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.dataSource, props.report, props.prepareData],
  );
  const rowHeight = React.useMemo(
    () => props.rowHeight || (props.report ? 35 : 49),
    [props.report, props.rowHeight],
  );
  const headerHeight = React.useMemo(
    () => props.headerHeight || 42,
    [props.headerHeight],
  );
  const length = React.useMemo(
    () => dataSource?.length,
    [dataSource?.length],
  );
  const gridStyle = React.useMemo(
    () => ({
      minHeight: props.autoHeight ? (headerHeight + rowHeight + 10) : undefined,
      height: props.autoHeight ? (headerHeight + ((length === 0 ? 1 : length) * rowHeight) + 3) : undefined,
    }),
    [props.autoHeight, headerHeight, length, rowHeight],
  );

  return (
    <ReactDataGrid
      licenseKey={process.env.REACT_APP_RDG_LICENCE_KEY}
      idProperty="id"
      style={gridStyle}
      sortable={false}
      reorderColumns={false}
      showColumnMenuTool={false}
      theme="default-dark"
      rowHeight={rowHeight}
      headerHeight={headerHeight}
      scrollProps={{
        autoHide: !props.report,
      }}
      {...props}

      sortInfo={props.preference ? sortInfo : props.sortInfo}
      columnOrder={props.preference ? columnOrder : props.columnOrder}
      filterValue={props.preference ? filterValue : props.filterValue}
      onColumnResize={props.preference ? onColumnResize : props.onColumnResize}
      onColumnOrderChange={props.preference ? onColumnOrderChange : props.onColumnOrderChange}
      onFilterValueChange={props.preference ? onFilterValueChange : props.onFilterValueChange}
      onColumnVisibleChange={props.preference ? onColumnVisibleChange : props.onColumnVisibleChange}

      columns={columns}
      loading={props.report ? loading : (props.loading || false)}
      dataSource={dataSource}
    />
  )
});

export default Grid;
