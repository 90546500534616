import React from 'react'
import classes from './button.module.scss'
import { cn } from '../../utils/cn'
import Spinner from '../spinner/spinner'

const Button = ({ type, onClick, disabled, loading, children, right, small }) => {

  if (loading) {
    return <Spinner small right />
  }
  
  return (
    <button
      className={cn(
        classes.button,
        right && classes.right,
        small && classes.small,
      )}
      onClick={onClick}
      disabled={disabled}
      type={type || 'submit'}
    >
      {children}
    </button>
  );
}

export default Button;
