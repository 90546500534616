import React from 'react'
import useApi, { CONTROLLER } from '../../hooks/useApi'
import useParams from '../../hooks/useParams'
import Spinner from '../../components/spinner/spinner'

const params = ['email', 'userid', 'code'];

const Verification = () => {
  const { loading, payload, execute } = useApi(CONTROLLER.VERIFICATION);
  const [email, userid, code] = useParams(params);
  
  React.useEffect(() => {
    execute({ email, userid, code });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, email, userid]);

  if (loading) {
    return <Spinner />
  }

  return (
    <div className="bodySec whatWeDoSec">
      <div className="container">
        <div className="row notfound">
          <div className="col-12">
            <div className="whatWeDoTxt">
              <h2 style={{textAlign: "center"}}>
                <span>
                  ACCOUNT VERIFICATION
                </span>
              </h2>
              <p>
                {payload?.message}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Verification;
