import React from 'react'

const Steps = ({ step }) => {
  return (
    <ul>
      <li id="personal" className={step === 1 ? 'active' : undefined}>personal</li>
      <li id="address" className={step === 2 ? 'active' : undefined}>address</li>
      <li id="signup" className={step === 3 ? 'active' : undefined}>sign up</li>
    </ul>
  )
}

export default Steps;
