import HighStock from 'highcharts/highstock'

const defined = HighStock.defined, Additions = HighStock._modules['Core/Axis/OrdinalAxis.js'].Additions;

Additions.prototype.getIndexOfPoint = function (val, ordinalArray) {
  const ordinal = this, axis = ordinal.axis, firstPointVal = ordinal.positions ? ordinal.positions[0] : 0;
  let firstPointX = (axis.series[0].points && axis.series[0].points[0] && axis.series[0].points[0].plotX) || axis.minPixelPadding; // #15987

  // When more series assign to axis, find the smallest one, #15987.
  if (axis.series.length > 1) {
    axis.series.forEach((series) => {
      if (series.points && defined(series.points[0]) && defined(series.points[0].plotX) && series.points[0].plotX < firstPointX) {
        firstPointX = series.points[0].plotX;
      }
    });
  }

  // Distance in pixels between two points
  // on the ordinal axis in the current zoom.
  const ordinalPointPixelInterval = axis.translationSlope * (ordinal.slope || axis.closestPointRange || ordinal.overscrollPointsRange),
    // toValue for the first point.
    shiftIndex = (val - firstPointX) / ordinalPointPixelInterval;

  return Additions.findIndexOf(ordinalArray, firstPointVal) + shiftIndex;
};
