import React from 'react'
import {cn} from '../../utils/cn'
import {capitalizeAllWords} from "../../utils/text";
import classes from './menu.module.scss'

const Menu = ({value, onChange, items}) => {
  if (items.length < 2) {
    return null;
  }

  return (
    <div className={classes.menu}>
      {items.map(item => (
        <span
          key={item}
          onClick={onChange && (() => onChange(item))}
          className={cn(classes.link, value === item && classes.active)}
        >
          {capitalizeAllWords(item)}
        </span>
      ))}
    </div>
  )
}

export default Menu;
