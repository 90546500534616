import React from 'react'
import axios from 'axios'
import {v4} from 'uuid'
import {generatePath} from 'react-router-dom'
import {StoreContext} from '../store'

const METHOD = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
}

export const CONTROLLER = {
  AUTH: {
    method: METHOD.GET,
    url: '/auth',
  },
  LOGOUT: {
    method: METHOD.POST,
    url: '/logout',
  },
  LOGIN: {
    method: METHOD.POST,
    url: '/login',
  },
  SIGNUP: {
    method: METHOD.POST,
    url: '/register',
  },
  FORGOT_PASSWORD: {
    method: METHOD.POST,
    url: '/forgot-password',
  },
  CHANGE_PASSWORD: {
    method: METHOD.POST,
    url: '/change-password',
  },
  CONTACT: {
    method: METHOD.POST,
    url: '/contact',
  },
  CHART_FULL: {
    method: METHOD.GET,
    url: '/chart/prediction/:ticker',
  },
  CHART_SMALL: {
    method: METHOD.GET,
    url: '/chart/small/:ticker',
  },
  CHART_FUTURE: {
    method: METHOD.POST,
    url: '/chart/future/:ticker',
  },
  CHART_VECTOR: {
    method: METHOD.POST,
    url: '/chart/vector/:ticker',
  },
  TICKERS_LOAD: {
    method: METHOD.GET,
    url: '/tickers',
  },
  SEARCH: {
    method: METHOD.POST,
    url: '/search',
  },
  VERIFICATION: {
    method: METHOD.POST,
    url: '/verification',
  },
  RESEND_VERIFICATION: {
    method: METHOD.POST,
    url: '/resend-verification',
  },
  PLAN: {
    method: METHOD.GET,
    url: '/plan/:planId',
  },
  PROMO: {
    method: METHOD.POST,
    url: '/promo/:planId',
  },
  AUTHORIZE: {
    method: METHOD.POST,
    url: '/create/authorize',
  },
  PAYPAL: {
    method: METHOD.POST,
    url: '/create/paypal',
  },
  UNSUBSCRIBE: {
    method: METHOD.POST,
    url: '/unsubscribe',
  },
  ORDERS: {
    method: METHOD.GET,
    url: '/orders',
  },
  CANCEL_SUBSCRIPTION: {
    method: METHOD.POST,
    url: '/cancel',
  },
  REPORTS: {
    method: METHOD.POST,
    url: '/reports/',
  },
  PREF_SAVE: {
    method: METHOD.POST,
    url: '/preferences/:name',
  },
}

const none = {};

const useApi = (controller, options = none) => {
  const [loading, setLoading] = React.useState(null);
  const [payload, setPayload] = React.useState();
  const {setResponse} = React.useContext(StoreContext);

  const execute = React.useCallback(async (data = undefined, {urlPath, urlParams} = {}) => {
    setLoading(true);
    const response = await axios.request({
      ...(controller || {}),
      url: generatePath(
        controller.url + (urlPath || options?.urlPath || ''),
        Object.assign({}, options?.urlParams || {}, urlParams || {}),
      ),
      data,
      baseURL: '/api/',
      headers: {'X-Request-Id': v4()},
    });
    setPayload(response.data);
    setResponse(response.data);
    setLoading(false);
    return response.data;
  }, [controller, options?.urlPath, options?.urlParams, setResponse]);

  return React.useMemo(() => ({loading, execute, payload}), [execute, loading, payload]);
};

export default useApi;
