import React from 'react'

const NotFound = () => (
  <div className="bodySec whatWeDoSec">
    <div className="container">
      <div className="row notfound">
        <div className="col-12">
          <div className="whatWeDoTxt">
            <h2 style={{textAlign: "center"}}>
              <span>404</span>
            </h2>
            <p>This page not found</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default NotFound;
