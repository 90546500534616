import React from 'react'
import { cn } from '../../utils/cn'
import { fields } from './fields'
import classes from './menu.module.scss'

const Menu = ({
  predictions = {},
  value = 'daily_prediction',
  onChange = () => {},
}) => {
  const data = Object.keys(fields).filter(field => predictions[field]);
  if (data.length < 2) {
    return null;
  }

  return (
    <div className={classes.menu}>
      {data.map(field => (
        <span
          key={field}
          onClick={() => onChange(field)}
          className={cn(classes.link, value === field && classes.active)}
        >
          {fields[field].item}
        </span>
      ))}
      <span
        onClick={() => onChange('all')}
        className={cn(classes.link, value === 'all' && classes.active)}
      >
        all
      </span>
    </div>
  )
}

export default Menu;
