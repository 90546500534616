import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-dnd'
import Spinner from '../../components/spinner/spinner'
import DashboardMenu from '../../components/dashboardMenu/dashboardMenu'
import { StoreContext } from '../../store'
import { useCharts } from '../../stocks'
import Stock from './stock'
import Pagination from './pagination'
import Button from '../../components/form/button'
import Select from './select/select'
import Confirm from '../../components/confirm/confirm'
import useApi, { CONTROLLER } from '../../hooks/useApi'
import useWidth from '../../hooks/useWidth'
import './dnd.css'

const countPerPage = 12;

const Stocks = () => {
  const { push } = useHistory();
  const [ width, ref ] = useWidth();
  const { page = '1' } = useParams();
  
  const { loading, data: list, setTickers } = useCharts();
  const { store: { paid, preferences: { stocks } = {} } } = React.useContext(StoreContext);
  const { loading: loadingSave, execute: executeSave } = useApi(CONTROLLER.PREF_SAVE, {urlParams: {name: 'stocks'}});

  const [show, setShow] = React.useState(false);
  const [cards, setCards] = React.useState([]);
  const [confirmShow, setConfirmShow] = React.useState(false);
  
  const countPerRow = React.useMemo(() => Math.floor(width / 315), [width]);
  
  React.useEffect(() => {
    setCards(paid && stocks ? stocks : []);
  }, [paid, stocks]);
  
  const start = React.useMemo(
    () => (parseInt(page, 10) - 1) * countPerPage,
    [page],
  );
  
  const isDiff = React.useMemo(
    () => JSON.stringify(stocks) !== JSON.stringify(cards),
    [cards, stocks],
  );
  
  React.useEffect(() => {
    if (cards) {
      setTickers(cards.slice(start, start + countPerPage));
    }
  }, [setTickers, start, cards]);

  const addStockHandler = React.useCallback(() => {
    if (paid) {
      setShow(true);
    } else {
      push("/pricing-plans")
    }
  }, [push, paid]);
  
  const handleOk = React.useCallback(async () => {
    await executeSave(cards);
    setConfirmShow(false);
  }, [cards, executeSave]);
  
  const onChange = React.useCallback((sourceId, sourceIndex, targetIndex, targetId) => {
    setCards(swap(cards, sourceIndex + start, targetIndex + start));
  }, [cards, start]);
  
  const onRemove = React.useCallback((card) => {
    setCards(cards.filter(temp => temp !== card))
  }, [cards]);
  
  const styles = React.useMemo(
    () => ({ height: `${300 * Math.ceil(cards.slice(start, start + countPerPage).length / countPerRow)}px` }),
    [cards, countPerRow, start],
  );
  
  if (loading || stocks === null) {
    return <Spinner />
  }

  return (
    <div className="stockSec">
      <div className="container">
        <DashboardMenu />
        <div className="row">
          <div className="col-12">
            <h2>
              <span>Stocks</span>
            </h2>
          </div>
        </div>
        <div className="row" ref={ref}>
          {cards.length === 0 && (
            <div className="col-12 text-center" style={{ paddingTop: "10%", paddingBottom: "10%" }}>
              No stock data. Click on Add Stock to add stock predictions.
            </div>
          )}
          {cards.length > 0 && (
            <GridContextProvider onChange={onChange}>
              <GridDropZone
                id="dropzone"
                className="dropzone"
                style={styles}
                boxesPerRow={countPerRow}
                rowHeight={300}
              >
                {list.map((item) => (
                  <GridItem key={item?.info?.ticker}>
                    <Stock
                      ticker={item?.info?.ticker}
                      data={item}
                      onRemove={onRemove}
                    />
                  </GridItem>
                ))}
              </GridDropZone>
            </GridContextProvider>
          )}
        </div>
  
        {cards.length !== 0 && (
          <div className="row">
            <div className="col-12">
              <div className="pageMain">
                <Pagination
                  showPerPage={countPerPage}
                  total={cards.length}
                />
              </div>
            </div>
          </div>
        )}
  
        <div className="row">
          <div className="col-6">
            {(cards?.length > 0 || (stocks?.length > 0)) && (
              <Button
                onClick={() => setConfirmShow(true)}
                disabled={!isDiff}
                loading={loadingSave}
              >
                Save
              </Button>
            )}
          </div>
          <div className="col-6">
            <Button
              right
              onClick={() => addStockHandler()}
            >
              Add Stocks
            </Button>
          </div>
          {show && (
            <Select
              show={show}
              handleClose={() => setShow(false)}
            />
          )}
          <Confirm
            show={confirmShow}
            title="Save Layout Confirmation"
            message="Save Layout?"
            handleOk={handleOk}
            handleClose={() => setConfirmShow(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default Stocks;
