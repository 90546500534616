import React from "react";
import {useHistory, useParams} from 'react-router-dom'
import Popup from "../../components/popup/popup";
import useApi, {CONTROLLER} from '../../hooks/useApi'
import {Form} from '../../components/form/form'
import Field from '../../components/form/field'
import useAOS from '../../hooks/useAOS'
import Button from '../../components/form/button'
import Spinner from '../../components/spinner/spinner'
import useQuora from '../../hooks/useQuora'
import {StoreContext} from '../../store'
import usePromo from "../../hooks/usePromo";
import {cn} from "../../utils/cn";

const gAdsPurchase = process.env.REACT_APP_GTAG_ID_PURCHASE;
const gAdsSubscribe = process.env.REACT_APP_GTAG_ID_SUBSCRIBE;

const Checkout = () => {
  const actions = useAOS();
  const {planId} = useParams();
  const {store: {email}} = React.useContext(StoreContext);
  const track = useQuora(email);
  const {goBack, push} = useHistory();
  const {loading: planLoading, payload: planPayload, execute: planExecute} = useApi(CONTROLLER.PLAN);
  const {loading: authLoading, payload: authPayload, execute: authExecute} = useApi(CONTROLLER.AUTHORIZE);
  const defPromo = new window.URLSearchParams(window.location.search).get('promo');
  const {promo, setPromo, isPromo, isNotPromo, loading, data: promoData} = usePromo(planId, defPromo);

  const [state, setState] = React.useState({
    show: false,
    title: "",
    message: "",
  });

  const onSubmit = async (values) => {
    const {success, message, subscription_id} = await authExecute({...values, id: planId, promo});

    if (success) {
      let message = '';
      if (planPayload.data.Type === 'One Time') {
        message = `Your One Time payment of $${planPayload.data.Amount} was successful.`
        // --- facebook ---
        window.fbq && window.fbq('track', 'Purchase', {value: planPayload.data.Amount, currency: 'USD'});
        // --- google ---
        const transaction_id = Date.now();
        const params = {
          event_category: 'ecommerce',
          subscription_id,
          transaction_id: Date.now(),
          affiliation: 'deeptrading.ai',
          value: planPayload.data.Amount,
          currency: 'USD',
          tax: 0,
          shipping: 0,
          items: [{
            id: (`${planPayload.data.Type} ${planPayload.data.Name}` || '').replaceAll(/\s+/g, ''),
            name: `${planPayload.data.Type} ${planPayload.data.Name}`,
            quantity: 1,
            price: planPayload.data.Amount,
          }],
        };
        window.gtag && window.gtag('event', 'purchase', params);
        window.gtag && window.gtag('event', 'conversion', {send_to: gAdsPurchase, value: planPayload.data.Amount, currency: 'USD', transaction_id});
      } else {
        message = `Your subscription payment of $${planPayload.data.Amount} was successful${isPromo ? ' with one month free trial' : ''}.`
        // --- twitter ---
        window.twq && window.twq('event', 'tw-o9bb4-od1ee', {
          value: planPayload.data.Amount,
          currency: 'USD',
          conversion_id: subscription_id,
        });
        // --- facebook ---
        window.fbq && window.fbq('track', 'Subscribe', {
          subscription_id,
          value: planPayload.data.Amount,
          currency: 'USD',
        });
        isPromo && window.fbq && window.fbq('track', 'StartTrial', {
          subscription_id,
          value: planPayload.data.Amount,
          currency: 'USD',
        });
        // --- google ---
        const transaction_id = Date.now();
        const params = {
          event_category: 'ecommerce',
          subscription_id,
          transaction_id,
          affiliation: 'deeptrading.ai',
          value: planPayload.data.Amount,
          currency: 'USD',
          tax: 0,
          shipping: 0,
          items: [{
            id: (`${planPayload.data.Type} ${planPayload.data.Name}` || '').replaceAll(/\s+/g, ''),
            name: `${planPayload.data.Type} ${planPayload.data.Name}`,
            quantity: 1,
            price: planPayload.data.Amount,
          }],
        };
        window.gtag && window.gtag('event', 'purchase', params);
        window.gtag && window.gtag('event', 'subscribe', params);
        isPromo && window.gtag && window.gtag('event', 'start_trial', params);
        window.gtag && window.gtag('event', 'conversion', {send_to: gAdsSubscribe, value: planPayload.data.Amount, currency: 'USD', transaction_id});
      }
      setState({
        show: true,
        title: "Payment Confirmation",
        message,
      });
      track();
    } else {
      setState({
        show: true,
        title: "Payment Confirmation",
        message: message || 'Something went wrong.',
      });
    }
  };

  React.useEffect(() => {
    if (!planLoading) {
      actions();
    }
  }, [actions, planLoading]);

  React.useEffect(() => {
    if (planId) {
      planExecute(undefined, {urlParams: {planId}});
    } else {
      goBack();
    }
  }, [planExecute, goBack, planId]);

  const handleClose = () => {
    setState({
      show: false,
      title: "",
      message: "",
    });

    if (authPayload.success) {
      push("/cabinet/orders");
    }
  };

  const initialValues = React.useMemo(() => ({
    year: "2021",
    month: "01",
    amount: planPayload?.data?.Amount,
  }), [planPayload?.data?.Amount]);

  if (planLoading) {
    return <Spinner/>
  }

  return (
    <section
      style={{marginTop: "10%"}}
      className="container-fluid inner-Page"
    >
      <div className="card-panel">
        <div className="media wow fadeInUp">
          <div className="companyIcon"/>
          <div className="media-body">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h1 style={{color: "#2bb3ea"}}>
                    Payment
                  </h1>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-xs-12 col-md-6 col-lg-8"
                  style={{borderRadius: 5, padding: 10}}
                >
                  <div className="panel panel-primary">
                    <div className="creditCardForm">
                      <div className="payment">
                        <Form onSubmit={onSubmit} className="AnimationForm" initialValues={initialValues}>
                          <div className="row">
                            <div className="form-group col-md-8">
                              <Field
                                type="text"
                                name="name"
                                label="Name"
                                required
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <Field
                                type="text"
                                name="cvv"
                                label="CVV"
                                required
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group col-md-8">
                              <Field
                                type="text"
                                name="cardNumber"
                                label="Card Number"
                                required
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <Field
                                type="text"
                                name="amount"
                                label="Amount"
                                required
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group col-md-6">
                              <Field
                                type="expiration"
                                label="Expiration Date"
                                required
                              />
                            </div>
                            <div
                              className="form-group col-md-6"
                              style={{marginTop: 22, textAlign: "right"}}
                            >
                              {['Visa', 'Mastercard', 'Amex', 'Discover'].map((img) => (
                                <img
                                  id={img}
                                  alt={img}
                                  key={img}
                                  src={`/images/${img}.png`}
                                  style={{width: "20%", marginLeft: 10}}
                                />
                              ))}
                            </div>
                          </div>
                          <br/>
                          <div className="row">
                            <div className="col-4 col-lg-4 col-xl-4 col-sm-12 col-xs-12 col-xxs-12">
                              <Button loading={authLoading}>
                                Confirm Payment
                              </Button>
                            </div>
                            <div className="col-8 col-lg-8 col-xl-8 col-sm-12 col-xs-12 col-xxs-12">
                              <div
                                className="form-group row"
                                style={{maxWidth: '450px', marginLeft: 'auto', height: '85px'}}
                              >
                                <label htmlFor="promo" className="col-sm-4 col-form-label" style={{textAlign: 'right'}}>
                                  Promo Code:
                                </label>
                                <div className="col-sm-8">
                                  <div className="form-group position-relative">
                                    <input
                                      type="text"
                                      className={cn(
                                        "form-control",
                                        isPromo === true && 'is-valid',
                                        isNotPromo === true && 'is-invalid',
                                      )}
                                      id="promo"
                                      style={{textTransform: 'uppercase'}}
                                      value={promo}
                                      onChange={(e) => setPromo(e.target.value)}
                                      maxLength="8"
                                      readOnly={loading}
                                    />
                                    <div className="valid-feedback feedback-icon"/>
                                    <div className="invalid-feedback feedback-icon"/>
                                  </div>
                                  {isPromo && (
                                    <div className="valid-feedback d-block">
                                      {promoData?.message}
                                    </div>
                                  )}
                                  {isNotPromo && (
                                    <div className="invalid-feedback d-block">
                                      {promoData}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup
        {...state}
        handleClose={handleClose}
      />
    </section>
  );
}

export default Checkout;
