import React from 'react'
import { FormContext } from './form'
import { cn } from '../../utils/cn'

const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
const years = ["2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031"];

const Field = ({
  forwardedRef,
  type = 'text',
  onChange,
  required,
  label,
  name,
  value: internal,
  delay = 100,
  options = [],
  ...props
}) => {
  const { values, setValues } = React.useContext(FormContext);
  const [dirty, setDirty] = React.useState(false);
  const [active, _setActive] = React.useState(false);
  const value = React.useMemo(
    () => ![null, undefined].includes(internal) ? internal : values ? values[name] : undefined,
    [internal, name, values]
  );

  const setValue = React.useCallback((event) => {
    const newValue = (type === 'checkbox') ? event.target.checked : event.target.value;
    if (onChange) {
      onChange(newValue);
    }
    if (setValues) {
      setValues(name, newValue);
    }
  }, [name, onChange, setValues, type]);
  
  const setActive = React.useCallback(() => {
    if (!dirty) {
      setDirty(true);
    }
    _setActive(true);
  }, [dirty]);

  const setInactive = React.useCallback(() => {
    _setActive(false);
  }, []);
  
  const setYear = React.useCallback((event) => {
    setValues("year", event.target.value);
  }, [setValues]);

  const setMonth = React.useCallback((event) => {
    setValues("month", event.target.value);
  }, [setValues]);
  
  const className = React.useMemo(() => cn(
    required && 'required',
    //dirty && required && !value && 'input-error',
  ), [required]);
  
  if (type === 'expiration') {
    return (
      <div
        className="field"
        data-aos="fade-up"
        data-aos-delay={delay}
      >
        <select
          name="month"
          className={className}
          value={values.month}
          onChange={setMonth}
          style={{
            float: "left",
            width: 120,
            marginRight: 10,
          }}
        >
          {months.map((month) => (
            <option key={month} value={month} label={month} />
          ))}
        </select>
        <select
          name="year"
          className={className}
          style={{
            float: "left",
            width: 150,
          }}
          value={values.year}
          onChange={setYear}
        >
          {years.map((year) => (
            <option key={year} value={year} label={year} />
          ))}
        </select>
        <fieldset className={cn('active')}>
          {label}
          {required && (
            <span>&nbsp;*</span>
          )}
        </fieldset>
      </div>
    );
  }
  
  if (type === 'checkbox') {
    return (
      <label className="customCheck">
        {label}
        <input
          ref={forwardedRef}
          type={type}
          name={name}
          autoComplete="none"
          checked={Boolean(value)}
          onChange={setValue}
          onBlur={setInactive}
          onFocus={setActive}
          {...props}
        />
        <span className="checkmark" />
      </label>
    )
  }
  
  if (type === 'select') {
    return (
      <div
        className="field"
        data-aos="fade-up"
        data-aos-delay={delay}
      >
        <select
          ref={forwardedRef}
          name={name}
          className={className}
          onChange={setValue}
          onBlur={setInactive}
          onFocus={setActive}
          {...props}
        >
          <option value={null} />
          {options.map((item) => (
            <option value={item?.key || item?.value || item} key={item?.key || item?.value || item}>
              {item?.label || item?.value || item}
            </option>
          ))}
        </select>
        <fieldset className={cn((active || value) && 'active')}>
          {label}
          {required && (
            <span>&nbsp;*</span>
          )}
        </fieldset>
      </div>
    );
  }

  if (type === 'textarea') {
    return (
      <div
        className="field textarea"
        data-aos="fade-up"
        data-aos-delay={delay}
      >
        <label style={{padding: "0px 30px"}}>
          {label}
          {required && (
            <span>&nbsp;*</span>
          )}
        </label>
        <textarea
          ref={forwardedRef}
          name={name}
          value={String(value || '')}
          onChange={setValue}
          required={required}
          style={{ lineHeight: "26px", paddingTop: 0 }}
          onBlur={setInactive}
          onFocus={setActive}
          autoComplete="none"
          {...props}
        />
      </div>
    );
  }

  return (
    <div
      className="field"
      data-aos="fade-up"
      data-aos-delay={delay}
    >
      <input
        ref={forwardedRef}
        type={type}
        value={value || ""}
        onChange={setValue}
        required={required}
        className={className}
        onBlur={setInactive}
        onFocus={setActive}
        autoComplete="none"
        {...props}
      />
      <fieldset className={cn((active || value) && 'active')}>
        {label}
        {required && (
          <span>&nbsp;*</span>
        )}
      </fieldset>
    </div>
  );
}

export default Field;
