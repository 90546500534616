import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from '../form/button'

const divStyle = { background: "#231919" };
const titleStyle = { color: "#2bb3ea" };

const Popup = ({ show, title, message, handleClose }) => (
  <div style={divStyle}>
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title style={titleStyle}>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} small>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
);

export default Popup;
