import React from 'react'
import Grid from '../grid/grid'
import { prettyAmount } from '../../utils/amount'
import { dateToString } from '../../utils/date'

const columns = [
  {
    name: 'name',
    header: '',
    minWidth: 230,
    defaultFlex: 1,
    textAlign: 'center'
  },
  {
    name: 'prediction',
    header: 'Prediction',
    minWidth: 150,
    defaultFlex: 1,
    headerAlign: 'center',
    textAlign: 'center',
  },
  {
    name: 'date',
    header: 'Prediction Date',
    minWidth: 200,
    defaultFlex: 1,
    headerAlign: 'center',
    textAlign: 'center',
  },
];

const Predictions = ({
  predictions: {
    daily_prediction,
    daily_prediction_date,
    two_day_prediction,
    two_day_prediction_date,
    weekly_prediction,
    weekly_prediction_date,
    two_week_prediction,
    two_week_prediction_date,
    monthly_prediction,
    monthly_prediction_date,
    two_month_prediction,
    two_month_prediction_date,
    three_month_prediction,
    three_month_prediction_date,
    six_month_prediction,
    six_month_prediction_date,
    yearly_prediction,
    yearly_prediction_date,
  } = {},
}) => {

  const dataSource = [
    {
      id: 1,
      name: 'Daily Prediction',
      prediction: prettyAmount(daily_prediction),
      date: dateToString(daily_prediction_date),
    },
    {
      id: 2,
      name: 'Two Day Prediction',
      prediction: prettyAmount(two_day_prediction),
      date: dateToString(two_day_prediction_date),
    },
    {
      id: 3,
      name: 'Weekly Prediction',
      prediction: prettyAmount(weekly_prediction),
      date: dateToString(weekly_prediction_date),
    },
    {
      id: 4,
      name: 'Two Week Prediction',
      prediction: prettyAmount(two_week_prediction),
      date: dateToString(two_week_prediction_date),
    },
    {
      id: 5,
      name: 'Monthly Prediction',
      prediction: prettyAmount(monthly_prediction),
      date: dateToString(monthly_prediction_date),
    },
    {
      id: 6,
      name: 'Two Month Prediction',
      prediction: prettyAmount(two_month_prediction),
      date: dateToString(two_month_prediction_date),
    },
    {
      id: 7,
      name: 'Three Month Prediction',
      prediction: prettyAmount(three_month_prediction),
      date: dateToString(three_month_prediction_date),
    },
    {
      id: 8,
      name: 'Six Month Prediction',
      prediction: prettyAmount(six_month_prediction),
      date: dateToString(six_month_prediction_date),
    },
    {
      id: 9,
      name: 'Yearly Prediction',
      prediction: prettyAmount(yearly_prediction),
      date: dateToString(yearly_prediction_date),
    },
  ].filter(item => item.prediction);
  
  return (
    <div className="homeGraphTable">
      <Grid
        idProperty="id"
        columns={columns}
        dataSource={dataSource}
        className="singleStockChartTable"
        autoHeight
        rowHeight={35}
        headerHeight={45}
      />
    </div>
  )
}

export default Predictions;
