import React from "react";

const Faq = () => (
  <div className="bodySec aboutSec">
    <div className="container">
      <h2 className="text-center">
        <span>FAQ</span>
      </h2>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 termsofuseclass">
          <div className="text-bold">
            <span>
              1.  Can you describe what products or services you sell?
            </span>
          </div>
          <p className="text-justify marginParagraph">
            DeepTrading.ai sells stock price predictions for a select list of 250 stocks generated using advanced
            Artificial Intelligence / Deep Learning algorithms. Our models and algorithms are highly sophisticated
            that may outperform traditional approaches to trading or investing. We offer three plans:
          </p>
          <ol>
            <li className="text-justify marginParagraph">
              1. <span className="text-italic"> Basic - </span> Giving you access to daily and weekly stock price
              predictions using our advanced Artificial Intelligence Deep Learning models.
            </li>
            <li className="text-justify marginParagraph">
              2. <span className="text-italic"> Advanced - </span> Giving you access to daily, weekly, monthly,
              three-month, and yearly stock price predictions using our advanced Artificial Intelligence Deep
              Learning models along with API access.
            </li>
            <li className="text-justify marginParagraph">
              3. <span className="text-italic"> Enterprise - </span> Giving you access to custom model adjustments or
              using your own custom data.
            </li>
          </ol>

          <div className="text-bold">
            <span>
              2. Why are you called DeepTrading.ai? What does the word Deep imply?
            </span>
          </div>
          <p className="text-justify marginParagraph">
            We are called DeepTrading.ai because of our use of advanced Artificial Intelligence Deep Learning
            algorithms which the word “Deep” implies.
          </p>
          
          <div className="text-bold">
            <span>
              3. On what basis was your stock list chosen?
            </span>
          </div>
          <p className="text-justify marginParagraph">
            We chose our stocks based on how popular they are, the diversity of sectors, and most importantly, how
            much historical data they have. For that reason, we do not include recent IPOs due to the lack of data
            that is needed to train our AI models.
          </p>
          
          <div className="text-bold">
            <span>
              4. Why is your stock list only 250 long? Will you add more to the list?
            </span>
          </div>
          <p className="text-justify marginParagraph">
            We plan to add more stocks soon. Currently, it is limited to 250
            due to the enormous computing power required even to train and
            evaluate just a single stock — which can take a couple of hours or
            even a whole day sometimes.
          </p>
          
          <div className="text-bold">
            <span>
              5. Do you remove stocks from your list?
            </span>
          </div>
          <p className="text-justify marginParagraph">
            A stock will get removed from our list mainly if the ticker is delisted
            from the exchange because it was either bought out, goes bankrupt — or any
            other reason making it non-tradeable. When this happens, the stock will get
            replaced by another stock, preferably in the same sector but not always.
          </p>

          <div className="text-bold">
            <span>
              6. What is “Unseen Data”?
            </span>
          </div>
          <p className="text-justify marginParagraph">
            Unseen data is data that the model has never “seen” during its training process. Unseen data includes
            validation data, test data, etc. We use this type of data to evaluate the performance of our models.
          </p>

          <div className="text-bold">
            <span>
              7. How are your AI models trained?
            </span>
          </div>
          <p className="text-justify marginParagraph">
            We use a specialized process to train all our AI deep learning models. The training gets done in two
            phases. First, our models get trained to pick patterns with the highest accuracy on unseen (validation)
            data. This accuracy is based on a cutoff date of 90 tradeable business days (around four months). Also,
            this validation training is done for each stock every four weeks. In the second phase, the AI models are
            trained daily for all stocks after initializing the model weights from the latest validation training for
            that period. In this step, all data is used for training with no cutoff date. This phase aims to pick up
            the latest stock movement patterns and is lighter in duration compared to the validation phase.
          </p>

          <div className="text-bold">
            <span>
              8. What do your model prediction accuracies mean exactly?
            </span>
          </div>
          <p className="text-justify marginParagraph">
            Our prediction accuracies are based on how correctly our models predicted the direction of the stock
            price in unseen data. These include daily, weekly, monthly, three month, and yearly accuracies based on
            four months of unseen data.
          </p>

          <div className="text-bold">
            <span>
              9. Do you offer any trading strategies?
            </span>
          </div>
          <p className="text-justify marginParagraph">
            Currently, we do not offer any trading strategies – just stock price predictions. It is up to you to
            decide how to use these predictions for your own trading strategies. Having said that, in the near future,
            we do plan to offer trading strategies that will aim maximize returns using our predictions. Stay tuned.
          </p>

          <div className="text-bold">
            <span>
              10. Do you make any manual adjustments to the model stock price predictions?
            </span>
          </div>
          <p className="text-justify marginParagraph">
            No. We do not make any adjustments to our model’s predictions. We let the AI model predictions speak for
            themselves and leave the predictions unadjusted.
          </p>

          <div className="text-bold">
            <span>
              11. Are your models used by Hedge Funds?
            </span>
          </div>
          <p className="text-justify marginParagraph">
            No. Our models are highly specialized and proprietary. No hedge fund or Wall Street firm uses our models.
            Even though Hedge Funds use their own AI models, they are not used and trained the same way as ours.
            We believe our models are superior to anything in the industry.
          </p>

          <div className="text-bold">
            <span>
              12. What are these charts showing predictions and close prices?
            </span>
          </div>
          <p className="text-justify marginParagraph">
            The charts showcase our stock price predictions against the actual prices. We provide you these so you
            can see the historical performance of our AI models and make any informed decisions if you decide to
            invest. Remember, nothing is 100% so it helps to see how the model has been performing in the past.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default Faq;
