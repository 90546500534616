import React from 'react';
import Field from '../../components/form/field'

const Step1 = () => {
  return (
    <div className="row">
      <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <Field
          autoComplete="new-password"
          type="text"
          name="firstname"
          required
          label="First Name"
        />
      </div>
      <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
      <Field
        autoComplete="new-password"
        type="text"
        name="middlename"
        label="Middle Name"
      />
      </div>
      <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
      <Field
        autoComplete="new-password"
        type="text"
        name="lastname"
        required
        label="Last Name"
      />
      </div>
      <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
      <Field
        autoComplete="new-password"
        type="text"
        name="companyName"
        label="Company"
      />
      </div>
      <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
      <Field
        autoComplete="new-password"
        type="text"
        name="titleUser"
        label="Title"
      />
      </div>
      <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
      <Field
        autoComplete="new-password"
        type="email"
        name="email"
        required
        label="Email"
      />
      </div>
      <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
      <Field
        autoComplete="new-password"
        type="text"
        name="phone"
        maxLength={20}
        label="Phone"
      />
      </div>
    </div>
  );
}

export default Step1;
