import React from 'react'
import Field from '../../components/form/field'
import country from '../../components/form/country'

const Step2 = () => {
  return (
    <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <Field
              autoComplete="new-password"
              type="text"
              name="add1"
              required
              label="Street Address"
            />
        </div>
        <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
            <Field
              autoComplete="new-password"
              type="text"
              name="city"
              required
              label="City"
            />
        </div>
        <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
            <Field
              autoComplete="new-password"
              type="text"
              name="state"
              required
              label="State/Province"
            />
        </div>
        <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
            <Field
              autoComplete="new-password"
              type="text"
              name="zipcode"
              required
              label="Postal/Zip Code"
            />
        </div>
        <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
            <Field
              type="select"
              name="country"
              required
              options={country}
              label="Country"
            />
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <Field
              type="textarea"
              name="description"
              label="Company Description"
            />
            {/*<div*/}
            {/*  className="field textarea aos-init aos-animate"*/}
            {/*  data-aos="fade-up"*/}
            {/*>*/}
            {/*  <label style={{ padding: "0px 30px", margin: "0px" }}>*/}
            {/*    Company Description*/}
            {/*  </label>*/}
            {/*  <textarea*/}
            {/*    onChange={handlefieldchange}*/}
            {/*    name="description"*/}
            {/*    style={{ paddingTop: 0, lineHeight: "28px" }}*/}
            {/*  />*/}
            {/*</div>*/}
        </div>
    </div>
  );
}

export default Step2;
