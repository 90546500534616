import React from 'react'
import { NavLink } from 'react-router-dom'

const WrapperLink = ({ to, children }) => <NavLink activeClassName="orangeBtnByPage" to={to}>{children}</NavLink>;

const DashboardMenu = () => (
 <div className="row">
   <div className="col-12 col-lg-12 col-xl-12">
     <div className="dashHead">
       <h2>
         <span>Dashboard</span>
       </h2>
       <ul>
         <li>
           <WrapperLink to="/cabinet/search">Data Search</WrapperLink>
         </li>
         <li>
           <WrapperLink to="/cabinet/stocks">Stocks</WrapperLink>
         </li>
         <li>
           <WrapperLink to="/cabinet/orders">Orders</WrapperLink>
         </li>
         <li>
           <WrapperLink to="/cabinet/profile">Profile</WrapperLink>
         </li>
       </ul>
     </div>
   </div>
 </div>
);

export default DashboardMenu;
