import React from 'react'
import {StoreContext} from './store'
import useApi, {CONTROLLER} from './hooks/useApi'

const StocksContext = React.createContext({});

const useCharts = () => {
  const responses = React.useContext(StocksContext);
  const [tickers, setTickers] = React.useState([]);

  const loading = React.useMemo(() => {
    return tickers.find((ticker) => !responses[ticker]);
  }, [responses, tickers])

  const data = React.useMemo(() => {
    return tickers.map((ticker) => responses[ticker]);
  }, [responses, tickers])

  return React.useMemo(() => {
    return {loading, data, setTickers}
  }, [data, setTickers, loading]);
}

const Stocks = ({children}) => {
  const { store: { preferences: { stocks } = {} } } = React.useContext(StoreContext);
  const { execute } = useApi(CONTROLLER.CHART_SMALL);
  const [value, setValue] = React.useState({})

  React.useEffect(() => {
    (async () => {
      const page = 4;
      const pages = (stocks || []).length / page;
      for (let i = 0; i < pages; i++) {
        (await Promise.all(
          stocks
            .slice(i * page, i * page + page).map((ticker) => execute(undefined, {urlParams: {ticker}}))
        )).forEach((response) => {
          if (response.data?.info?.ticker) {
            setValue((item) => ({...item, [response.data.info.ticker]: response.data}));
          }
        });
      }
    })();
  }, [execute, stocks]);

  return (
    <StocksContext.Provider value={value}>
      {children}
    </StocksContext.Provider>
  );
};

export {Stocks, StocksContext, useCharts};
