export const close = {
  id: 'close',
  name: 'CLOSE',
  title: 'Close',
  color: '#0cc445',
  legendIndex: 0,
};

export const future = {
  id: 'future',
  name: 'FUTURE PRED',
  title: 'Future Pred',
  color: '#f05416',
  legendIndex: 10,
};

export const vector = {
  id: 'vector',
  type: 'vector',
  name: 'Return',
  yAxis: 1,
  legendIndex: 11,
  showInLegend: false,
};

export const ghost = {
  id: 'ghost',
  type: 'spline',
  name: 'ghost',
  yAxis: 1,
  legendIndex: 12,
  showInLegend: false,
  lineWidth: 0,
  states: {
    hover: {
      lineWidthPlus: 0
    }
  },
  marker: {
    enabled: false,
    states: {
      hover: {
        enabled: true,
        radius: 0
      }
    }
  },
};

export const fields = {
  daily_prediction: {
    id: 'daily',
    item: 'Daily',
    name: 'DAILY PRED',
    title: 'Daily',
    color: '#ff0000',
    legendIndex: 1,
  },
  two_day_prediction: {
    id: 'two_day',
    item: 'Two Day',
    name: 'TWO DAY PRED',
    title: 'Two Day',
    color: '#ff4a2c',
    legendIndex: 2,
  },
  weekly_prediction: {
    id: 'weekly',
    item: 'Weekly',
    name: 'WEEKLY PRED',
    title: 'Weekly',
    color: '#0099ff',
    legendIndex: 3,
  },
  two_week_prediction: {
    id: 'two_week',
    item: 'Two Week',
    name: 'TWO WEEK PRED',
    title: 'Two Week',
    color: '#00BBBB',
    legendIndex: 4,
  },
  monthly_prediction: {
    id: 'monthly',
    item: 'Monthly',
    name: 'MONTHLY PRED',
    title: 'Monthly',
    color: '#ff8818',
    legendIndex: 5,
  },
  two_month_prediction: {
    id: 'two_month',
    item: 'Two Month',
    name: 'TWO MONTH PRED',
    title: 'Two Month',
    color: '#ff559f',
    legendIndex: 6,
  },
  three_month_prediction: {
    id: 'three_month',
    item: 'Three Month',
    name: 'THREE MONTH PRED',
    title: 'Three Month',
    color: '#ee22ff',
    legendIndex: 7,
  },
  six_month_prediction: {
    id: 'six_month',
    item: 'Six Month',
    name: 'SIX MONTH PRED',
    title: 'Six Month',
    color: '#9b7cef',
    legendIndex: 8,
  },
 yearly_prediction: {
   id: 'yearly',
   item: 'Yearly',
   name: 'YEARLY PRED',
   title: 'Yearly Pred',
   color: '#a3a3c1',
   legendIndex: 9,
 },
};
