import React from 'react'
import { Link } from 'react-router-dom'
import Popup from '../../components/popup/popup'
import useAOS from '../../hooks/useAOS'
import useApi, { CONTROLLER } from '../../hooks/useApi'
import Field from '../../components/form/field'
import { Form } from '../../components/form/form'
import Button from '../../components/form/button'

const Login = () => {
  useAOS();
  const [username, setUsername] = React.useState("");
  const { execute: resendExecute } = useApi(CONTROLLER.RESEND_VERIFICATION);
  const { loading: loginLoading, payload: loginPayload, execute: loginExecute } = useApi(CONTROLLER.LOGIN)
  const [state, setState] = React.useState({ show: false, message: "", title: "" });

  const handleClose = () => {
    setState({ show: false, message: "", title: "" });
  };

  const resend = async () => {
    const { success } = await resendExecute({ username })
    if (success) {
      setState({
        show: true,
        title: "Action successful.",
        message: "Verification email has been sent",
      });
    } else {
      setState({
        show: true,
        title: "Action is not successful",
        message: "Something went wrong",
      })
    }
  };

  const onSubmit = async ({ username, password }) => {
    await loginExecute({ username, password });
  }

  return (
    <>
      <div className="bodySec storySec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <Form
                className="AnimationForm"
                onSubmit={onSubmit}
              >
                <div className="signUpTabs signIn">
                  <h2 className="text-left">
                    <span>Log In</span>
                  </h2>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <Field
                        type="text"
                        name="username"
                        label="Username / Email"
                        required
                        autoComplete={'off'}
                        onChange={setUsername}
                        autoFocus
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <Field
                        type="password"
                        name="password"
                        label="Password"
                        required
                        autoComplete={'off'}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div>
                        <Button loading={loginLoading} right>
                          Sign in
                        </Button>
                      </div>
                      <div className="text-danger">
                        {loginPayload?.code && (
                          <>
                            Your Account is Pending Email Verification. Click
                            &nbsp;<Link to="#" onClick={resend}>here</Link>&nbsp;
                            to resend the email to verify your account.
                          </>
                        )}
                        {!loginPayload?.code && loginPayload?.message}
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                      <div className="fotgotInfo">
                        <ul>
                          <li>
                            <Link to="/auth/signup">Sign up for an account</Link>
                          </li>
                          <li>
                            <Link to="/auth/forgot-password">Forgot Username/Password</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Popup
        {...state}
        handleClose={handleClose}
      />
    </>
  );
}

export default Login;
