import React from "react";
import moment from "moment";
import {Link, useHistory, useParams} from 'react-router-dom'
import {cn} from "../../utils/cn";
import Popup from "../../components/popup/popup";
import {StoreContext} from '../../store'
import {dateToString} from '../../utils/date'
import {prettyAmount} from '../../utils/amount'
import {PayPalBtn, PayPalOneTime} from "./paypal";
import Spinner from '../../components/spinner/spinner'
import usePromo from "../../hooks/usePromo";
import useQuora from '../../hooks/useQuora'
import useApi, {CONTROLLER} from '../../hooks/useApi'

const isProd = process.env.NODE_ENV === 'production';
const gAdsPurchase = process.env.REACT_APP_GTAG_ID_PURCHASE;
const gAdsSubscribe = process.env.REACT_APP_GTAG_ID_SUBSCRIBE;

const Order = () => {
  const {push} = useHistory();
  const {planId} = useParams();
  const {store: {username, email}} = React.useContext(StoreContext);
  const track = useQuora(email);
  const {payload: paypalPayload, execute: paypalExecute} = useApi(CONTROLLER.PAYPAL);
  const {loading: planLoading, payload: planPayload, execute: planExecute} = useApi(CONTROLLER.PLAN);
  const [state, setState] = React.useState({show: false, message: "", title: ""});
  const {promo, setPromo, isPromo, isNotPromo, loading, data: promoData} = usePromo(planId);

  React.useEffect(() => {
    planExecute(undefined, {urlParams: {planId}});
  }, [planExecute, planId]);

  React.useEffect(() => () => {
    for (const item of document.querySelectorAll('body script[src*="www.paypal.com/sdk"]')) {
      for (const param in window) {
        if (window?.hasOwnProperty(param) && param.endsWith(item.dataset?.uidAuto)) {
          delete window[param];
        }
      }
      item.remove();
    }
    window.paypal = undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (planPayload) {
      // --- facebook ---
      window.fbq && window.fbq('track', 'InitiateCheckout');
      // --- google ---
      window.gtag && window.gtag('event', 'begin_checkout', {
        event_category: 'ecommerce',
        affiliation: 'deeptrading.ai',
        value: planPayload.data.Amount,
        currency: 'USD',
        tax: 0,
        shipping: 0,
        items: [{
          id: (`${planPayload.data.Type} ${planPayload.data.Name}` || '').replaceAll(/\s+/g, ''),
          name: `${planPayload.data.Type} ${planPayload.data.Name}`,
          quantity: 1,
          price: planPayload.data.Amount,
        }],
      });
    }
  }, [planPayload]);


  const paypalSubscribe = async (data, {subscription}) => subscription.create({
    plan_id: (isPromo && promoData?.planId) || (isProd ? planPayload.data.PaypalPlanId : planPayload.data.PaypalPlanIdTest),
    custom_id: `${username}::${planId}::${planPayload.data.Amount}::${new Date().getTime()}`,
    application_context: {
      shipping_preference: "NO_SHIPPING",
    },
  });

  const paypalCreateOrder = (data, {order}) => order.create({
    purchase_units: [{
      amount: {
        currency_code: "USD",
        value: planPayload.data.Amount,
      },
      custom_id: `${username}::${planId}::${planPayload.data.Amount}::${new Date().getTime()}`,
    }],
    application_context: {
      shipping_preference: "NO_SHIPPING",
    },
  });


  const paypalOnApprove = async (data) => {
    const {success} = await paypalExecute({...data, promo});
    if (success) {
      setState({
        show: true,
        title: "Payment Confirmation",
        message: `Your subscription payment of $${planPayload.data.Amount} was successful${isPromo ? ' with one month free trial' : ''}.`,
      });
      track();
      // --- twitter ---
      window.twq && window.twq('event', 'tw-o9bb4-od1ee', {
        value: planPayload.data.Amount,
        currency: 'USD',
        conversion_id: data.subscriptionID,
      });
      // --- facebook ---
      window.fbq && window.fbq('track', 'Subscribe', {
        subscription_id: data.subscriptionID,
        value: planPayload.data.Amount,
        currency: 'USD',
      });
      isPromo && window.fbq && window.fbq('track', 'StartTrial', {
        subscription_id: data.subscriptionID,
        value: planPayload.data.Amount,
        currency: 'USD',
      });
      // --- google ---
      const transaction_id = Date.now();
      const params = {
        event_category: 'ecommerce',
        subscription_id: data.subscriptionID,
        transaction_id,
        affiliation: 'deeptrading.ai',
        value: planPayload.data.Amount,
        currency: 'USD',
        tax: 0,
        shipping: 0,
        items: [{
          id: (`${planPayload.data.Type} ${planPayload.data.Name}` || '').replaceAll(/\s+/g, ''),
          name: `${planPayload.data.Type} ${planPayload.data.Name}`,
          quantity: 1,
          price: planPayload.data.Amount,
        }],
      };
      window.gtag && window.gtag('event', 'purchase', params);
      window.gtag && window.gtag('event', 'subscribe', params);
      isPromo && window.gtag && window.gtag('event', 'start_trial', params);
      window.gtag && window.gtag('event', 'conversion', {send_to: gAdsSubscribe, value: planPayload.data.Amount, currency: 'USD', transaction_id});
    } else {
      setState({
        show: true,
        title: "Payment Confirmation",
        message: `Something went wrong.`,
      });
    }
  };

  const paypalOneTimeOnApprove = async (data) => {
    const {success} = await paypalExecute(data);
    if (success) {
      setState({
        show: true,
        title: "Payment Confirmation",
        message: `Your one time payment of $${planPayload.data.Amount} was successful.`,
      });
      track();
      // --- facebook ---
      window.fbq && window.fbq('track', 'Purchase', {value: planPayload.data.Amount, currency: 'USD'});
      // --- google ---
      const transaction_id = Date.now();
      const params = {
        event_category: 'ecommerce',
        subscription_id: data.subscriptionID,
        transaction_id,
        affiliation: 'deeptrading.ai',
        value: planPayload.data.Amount,
        currency: 'USD',
        tax: 0,
        shipping: 0,
        items: [{
          id: (`${planPayload.data.Type} ${planPayload.data.Name}` || '').replaceAll(/\s+/g, ''),
          name: `${planPayload.data.Type} ${planPayload.data.Name}`,
          quantity: 1,
          price: planPayload.data.Amount,
        }],
      };
      window.gtag && window.gtag('event', 'purchase', params);
      window.gtag && window.gtag('event', 'conversion', {send_to: gAdsPurchase, value: planPayload.data.Amount, currency: 'USD', transaction_id});
    } else {
      setState({
        show: true,
        title: "Payment Confirmation",
        message: `Something went wrong.`,
      });
    }
  };

  const handleClose = () => {
    setState({
      show: false,
      title: "",
      message: "",
    });

    if (paypalPayload.success) {
      push("/cabinet/orders");
    }
  };

  if (planLoading || !planPayload?.data) {
    return <Spinner/>
  }

  return (
    <section className="dashboardSec">
      <div className="dashOrdSec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <h2>
                <span>Review your Order</span>
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="orderTable">
                <table className="table">
                  <thead>
                  <tr>
                    <th valign="middle">Order date</th>
                    <th valign="middle">Product</th>
                    <th valign="middle">Payment</th>
                    <th valign="middle">Expires</th>
                    <th valign="middle">Amount</th>
                  </tr>
                  </thead>

                  <tbody>
                  <tr>
                    <td>
                      {dateToString()}
                    </td>
                    <td style={{textTransform: "capitalize"}}>
                      {planPayload.data.Name}
                    </td>
                    <td>
                      {planPayload.data.Type}
                    </td>
                    <td>
                      {planPayload.data.Type === "Monthly" && moment().add(1, "months").format("MMMM Do, YYYY")}
                      {planPayload.data.Type === "Yearly" && moment().add(1, "years").format("MMMM Do, YYYY")}
                      {planPayload.data.Type === "One Time" && moment().add(7, "days").format("MMMM Do, YYYY")}
                    </td>
                    <td>
                      ${prettyAmount(planPayload.data.Amount)}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row">
            <div style={{marginTop: 27}} className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
              {planPayload.data.Type === 'One Time' ? (
                <PayPalOneTime
                  createOrder={paypalCreateOrder}
                  onApprove={paypalOneTimeOnApprove}
                />
              ) : (
                <PayPalBtn
                  amount={planPayload.data.Amount}
                  createSubscription={paypalSubscribe}
                  onApprove={paypalOnApprove}
                />
              )}
            </div>
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-4">
              <Link
                style={{marginTop: "3%"}}
                to={`/cabinet/checkout/${planPayload.data.Id}${isPromo ? `?promo=${promo}` : ``}`}
              >
                {['Visa', 'Mastercard', 'Amex', 'Discover'].map((img) => (
                  <img
                    id={img}
                    alt={img}
                    key={img}
                    src={`/images/${img}.png`}
                    style={{width: "70px", marginLeft: 10}}
                  />
                ))}
              </Link>
            </div>
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-5">
              <div
                className="form-group row"
                style={{marginTop: '15px', maxWidth: '450px', marginLeft: 'auto', height: '85px'}}
              >
                <label htmlFor="promo" className="col-sm-4 col-form-label" style={{textAlign: 'right'}}>
                  Promo Code:
                </label>
                <div className="col-sm-8">
                  <div className="form-group position-relative">
                    <input
                      type="text"
                      className={cn(
                        "form-control",
                        isPromo === true && 'is-valid',
                        isNotPromo === true && 'is-invalid',
                      )}
                      id="promo"
                      style={{textTransform: 'uppercase'}}
                      value={promo}
                      onChange={(e) => setPromo(e.target.value)}
                      maxLength="8"
                      readOnly={loading}
                    />
                    <div className="valid-feedback feedback-icon"/>
                    <div className="invalid-feedback feedback-icon"/>
                  </div>
                  {isPromo && (
                    <div className="valid-feedback d-block">
                      {promoData?.message}
                    </div>
                  )}
                  {isNotPromo && (
                    <div className="invalid-feedback d-block">
                      {promoData}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup
        {...state}
        handleClose={handleClose}
      />
    </section>
  );
}

export default Order;
