import React from 'react';
import { Link } from 'react-router-dom'
import Field from '../../components/form/field'

const Confirm = () => {
  return (
    <div className="row">
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <Field
          type="checkbox"
          name="policy"
          label={
            <>
              I agree to the DeepTrading.ai
              <Link target="_blank" to="/terms">
                &nbsp;
                Terms of Use
                &nbsp;
              </Link>
              and
              <Link target="_blank" to="/policy">
                &nbsp;
                Privacy Policy
                &nbsp;
              </Link>
            </>
          }
        />
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <Field
          type="checkbox"
          name="mailinglist"
          label="Subscribe to Mailing List"
        />
      </div>
    </div>
  )
};

export default Confirm;
