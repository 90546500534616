import React from 'react';

const action = () => {
  window.AOS.init({
    duration: 500,
    easing: 'ease-out-quart',
    once: true
  });
};

const useAOS = () => {
  React.useEffect(() => {
    action();
  }, []);
  return action;
}

export default useAOS;
